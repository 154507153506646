import { Box } from '@mui/material';
import { sideBarLogo, sidebarLogoStyles,sidebarLogoImgStyles } from '../config';

const SidebarLogo = ({ logo }) => {
  return (
    <Box sx={sidebarLogoStyles}>
      <img src={logo ? logo : sideBarLogo} style={sidebarLogoImgStyles} alt="sidebar logo" />
    </Box>
  )
};

export default SidebarLogo;
