import React, { useRef, useState,useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useUtility } from './useUtility';
import { Box, Button, Menu, MenuItem } from '@mui/material';

const RouteHistoryActionColumn = ({ data }) => {
  const navigate = useNavigate();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const menuRef = useRef(null);

  const { setItemIdForView, setViewModalOpen } = useUtility();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_delivery_groups_tab');
  const actions = [
    {
      name: 'View Route',
      permission: permissions.view,
    },
  ];




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDisplayed(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  
  return (
    <div>
      <span>
        <Button
          variant="filled"
          onClick={() => {
            setIsDisplayed(!isDisplayed);
          }}
          sx={{
            borderRadius: '20px',
            textTransform: 'capitalize',
            px: { md: 3, lg: 3, sm: 2 },
            borderRadius: '50px',
            fontWeight: 400,
            color: 'white',
            boxShadow: 'none',
          }}
        >
          <span style={{ paddingLeft: '11px', paddingRight: '11px' }}>
            Actions
          </span>   
        </Button>
        {isDisplayed && (
          <Box
            ref={menuRef}
            style={{
              zIndex: 200,
              alignContent: 'center',
              backgroundColor: 'white',
              position: 'absolute',
              right:'24px',
              borderRadius: '10px',
              width: '150px',
              display: 'block',
              boxShadow: '0px 4px 4px 0px #00000026',
              marginTop: '5px',
            }}
          >
            {actions.map(
              (action) =>
                action.permission && (
                  <Box key={action.name}>
                    <Button
                      sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                      onClick={() => {
                        setItemIdForView(data);
                        setViewModalOpen(true);
                        setAnchorEl(null);
                      }}
                    >
                      {action.name}
                    </Button>
                  </Box>
                )
            )}
          </Box>
        )}
      </span> 
    </div>
  );
};

export default RouteHistoryActionColumn;
