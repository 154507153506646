import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import { ReactComponent as HouseIcon } from './assets/House.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrowright.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Add the unique class to the body
    document.body.classList.add('customPage');

    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);

  return (
    <CustomizationLandingPageLayout>
      <div className="checkout-page">
        {/* BreadCrumbs */}
        <Grid
          container
          sx={{
            padding: '1em 5em',
            backgroundColor: '#F0645B',
          }}
        >
          <Grid item md={12}>
            <Box
              display={'flex'}
              flex={1}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
            >
              <HouseIcon />
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={15}
                color={'#ffffff'}
                fontWeight={'bold'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                Home
              </Typography>
              <ArrowRightIcon />
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={15}
                color={'#ffffff'}
                fontWeight={'bold'}
              >
                Shopping Cart
              </Typography>
              <ArrowRightIcon />
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={15}
                color={'#ffffff'}
                fontWeight={'bold'}
              >
                Checkout
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            padding: '4em 5em',
          }}
        >
          <Grid item md={12}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                padding: '2em',
                maxWidth: '650px',
                margin: '0 auto',
              }}
            >
              <SuccessIcon />
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={24}
                color={'#000000'}
                textAlign={'center'}
              >
                Your order is successfully place
              </Typography>
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={18}
                color={'#5F6C72'}
                textAlign={'center'}
                lineHeight={'29px'}
              >
                Pellentesque sed lectus nec tortor tristique accumsan quis
                dictum risus. Donec volutpat mollis nulla non facilisis.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  width: '40%',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
                onClick={() => navigate('/')}
              >
                go to home page
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </CustomizationLandingPageLayout>
  );
};

export default Checkout;
