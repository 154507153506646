import RouteHistoryActionColumn from './routeHistoryActionColumn';

const RouteHistoryColumns = [
  {
    field: 'route_id',
    headerName: 'Route ID',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route_id}</div>
    ),
  },
  {
    field: 'route_name',
    headerName: 'Route Name',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route_name}</div>
    ),
  },
  {
    field: 'delivered_by',
    headerName: 'Delivered By',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.delivered_by}</div>
    ),
  },
  {
    field: 'total_deliveries',
    headerName: 'Deliveries',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.total_deliveries}</div>
    ),
  },
  {
    field: 'stop_count',
    headerName: 'Stop Count',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.stop_count}</div>
    ),
  },
  {
    field: 'total_distance_km',
    headerName: 'Route Length (km)',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.total_distance_km}</div>
    ),
  },  
  {
    field: 'delivery_date',
    headerName: 'Date',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        {params.row.delivery_date === null
          ? 'N/A'
          : new Date(params.row.delivery_date).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric', 
              year: 'numeric'
            })}
      </div>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return <RouteHistoryActionColumn data={params.row} />;
    },
  },
];

export default RouteHistoryColumns;
