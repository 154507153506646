import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Datacard = ({ image, data, message, index, imgWidth = '49px' }) => {
  const [bordercolor, setborderColor] = useState('');
  const theme = useTheme();
  useEffect(() => {
    if (index === 1) {
      setborderColor('#FF6B00');
    }
    if (index === 2) {
      setborderColor('#00C2FF');
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #EBEBEC',
          padding: '15px',
          borderRadius: '15px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          boxShadow: '0px 3px 5px 0px #00000026',
          border: '1px solid #EBEBEC',
          width: '100%',
          // borderColor: bordercolor,
          gap: '22px',
        }}
      >
        <img
          src={require(`../../assets/cards/${image}.png`)}
          style={{ width: imgWidth }}
        />
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins',

              fontSize: '16px',
              [theme.breakpoints.up(1600)]: {
                fontSize: '18px',
              },
              fontWeight: '600',
              lineHeight: '30px',
              letterSpacing: '0px',
              textAlign: 'left',
              color: '#3F2F5B',
            }}
          >
            {data ? data : 'N/A'}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#3F2F5B',
              fontFamily: 'Poppins',
            }}
          >
            {message ? message : ''}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Datacard;
