import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Chip,
  IconButton,
  Badge,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import { TENANT_URL } from '../../../config';

/** Custom Styled Accordion */
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '4px',
  boxShadow: 'none',
  '&:before': { display: 'none' },
  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiAccordionSummary-root:last-child': { borderBottom: 'none' },
  '& .MuiChip-root': { backgroundColor: '#F0645B' },
  '& .MuiChip-label': { color: 'white' },
  '& .MuiBadge-badge': { color: 'white', backgroundColor: '#F0645B' },
  '& .MuiAccordionDetails-root': { padding: '5px 16px 16px' },
}));

const AdditionalMenuModal = ({ open, handleClose, isUpdated, setAdditionalMenuIds, setRecallAPI }) => {
  const [mealItems, setMealItems] = useState({
    Everyday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  // Add useEffect to sync with parent
  useEffect(() => {
    setAdditionalMenuIds(mealItems);
  }, [mealItems]);

  const [mealItemsIds, setMealItemsIds] = useState({
    Everyday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  

  const handleAddMeal = (day, meal) => {
    if (meal) {
      let mealID = 0;

      Object.values(mealItems).forEach((entity) => {
        entity.forEach((element) => {
          if (element.name == meal) {
            mealID = element.id;
          }
        });
      });

      setMealItems((prev) => ({
        ...prev,
        [day]: [...prev[day], meal],
      }));

      setMealItemsIds((prev) => ({
        ...prev,
        [day]: [...prev[day], mealID],
      }));
    }
  };

  const [additionalMenu, setAdditionalMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleUpdateAdditional = async () => {
    const url = '/customer/app/additional-menu';
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.put(
        endpoint,
        {
          custom_menu: { ...mealItems },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      handleClose(response);
      setRecallAPI(true);
      // location.reload();
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const handleUpdateAdditionalTemp = async () => {
    const url = '/customer/app/temp-additional-items';
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.post(
        endpoint,
        {
          custom_menu: { ...mealItems },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      handleClose(response);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const handleUpdateAddi = () => {
    if(!!isUpdated) {
      handleUpdateAdditionalTemp()
    } else {
      handleUpdateAdditional()
    }
  }

  const fetchDataSubscription = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log('fetchDataSubscription:', response);
      setter(response.data.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const fetchDataMealsItem = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log('#####', response);
      setter(response.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
  
      await fetchDataMealsItem('/customer/app/additional-menu', (data) => {
        if (data?.data?.menu) {
          // Initialize temporary objects to hold mealItems and mealItemsIds
          const updatedMealItems = {
            Everyday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          };
  
          const updatedMealItemsIds = {
            Everyday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          };
  
          // Loop through the menu data to populate both mealItems and mealItemsIds
          data.data.menu.forEach((element) => {
            const day = element.day.charAt(0).toUpperCase() + element.day.slice(1).toLowerCase(); // Normalize day name
  
            // Ensure the day exists in the state structure
            if (updatedMealItems[day]) {
              updatedMealItems[day] = (element.items || []).map((item) => item.id); // Extract item ids
              updatedMealItemsIds[day] = (element.items || []).map((item) => item.id); // Extract item ids
            }
          });
  
          // Update the states
          setMealItems((prev) => ({
            ...prev,
            ...updatedMealItems,
          }));
        }
      });
  
      await fetchDataSubscription('/customer/app/items/all?is_active=true', setAdditionalMenu);
      setLoading(false);
    };
  
    if(!!isUpdated) {
      setMealItems({
        Everyday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      })
      setLoading(false);
    } else {
      fetchData();
    }
    
  }, [isUpdated]);

  const handleDeleteMeal = (day, meal) => {
    setMealItems((prev) => ({
      ...prev,
      [day]: prev[day].filter((item) => item !== meal),
    }));
  };

  const getTotalItems = () =>
    Object.values(mealItems).reduce(
      (total, dayItems) => total + dayItems.length,
      0
    );

  const getAllItems = () =>
    Object.entries(mealItems).flatMap(([day, items]) =>
      items.map((item) => ({ day, item }))
    );  
    

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Additional Menu Items
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 4 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Individual Days Accordion */}
            {Object.keys(mealItems).map((day, index) => (
              <StyledAccordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    sx={{ gap: '15px', display: 'flex', alignItems: 'center' }}
                  >
                    {day == 'Everyday'
                      ? 'Ongoing Items'
                      : day.charAt(0).toUpperCase() + day.slice(1)}{' '}
                    <Badge
                      badgeContent={mealItems[day].length}
                      color="primary"
                      style={{ marginLeft: 8 }}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                    {mealItems[day].map((meal, idx) => {
                      const findMeal = additionalMenu.find(
                        (item) => item.id == meal
                      );

                      return (
                        <Chip
                          key={idx}
                          label={findMeal?.name}
                          onDelete={() => handleDeleteMeal(day, meal)}
                          color="secondary"
                        />
                      );
                    })}
                  </div>
                  <Autocomplete
                    fullWidth
                    options={additionalMenu} // Use additionalMenu state as the source
                    getOptionLabel={(option) => option.name || ''} // Show item name
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Item and hit enter"
                        variant="outlined"
                      />
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        const selectedOption = additionalMenu.find(
                          (item) => item.name === e.target.value
                        );
                        if (selectedOption) {
                          handleAddMeal(day, selectedOption.id); // Add the selected item by ID
                          e.target.value = ''; // Clear the input field
                        }
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleAddMeal(day, newValue.id); // Add the selected item by ID
                      }
                    }}
                    style={{ marginTop: 16 }}
                    sx={{
                      '.MuiInputBase-root': {
                        padding: '5px',
                      },
                      '.MuiButtonBase-root': {
                        height: '30px',
                      },
                    }}
                  />
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: '15px' }}>
        <Button
          onClick={() =>
            setMealItems({
              Everyday: [],
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            })
          }
          color="secondary"
          style={{ border: '1px solid #F0645B', color: '#F0645B' }}
        >
          Reset
        </Button>
        <Button
          // onClick={handleClose}
          onClick={handleUpdateAddi}
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#F0645B' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalMenuModal;
