import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    styled,
    Typography
  } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import { useUtility } from '../../useUtility';
import { useRouteHistory } from '../../../../../tenant/customHooks/useRouteHistory';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
  
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      width: 550,
      paddingBlock: '10px',
      borderRadius: '10px',
    },
}));



const ViewModal = ({ open, setOpen }) => {
  const { itemIdForView, setItemIdForView } = useUtility();
  const { getRouteHistoryById } = useRouteHistory();
  const [mappedData, setMappedData] = useState([]);
 

  useEffect(() => {
    getViewItem();
  }, [open]);

  const getViewItem = async () => {
    if (itemIdForView && open) {
      await getRouteHistoryById(itemIdForView).then((item) => {
        setMappedData(item.data);
      });
    }
  };

  const closeModal = () => {
    setOpen(false);
    setItemIdForView(null);
    setMappedData([]);
  }; 


  return (
    <StyledDialog open={open} onClose={closeModal}> 
        <DialogTitle>
            <Box sx={{ display: 'flex' }}>
            Route {itemIdForView ? itemIdForView.route_id : ''} Details
                <Box
                    sx={{
                    ml: 'auto',
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#EAEAEA',
                    borderRadius: '50%',
                    }}
                    onClick={closeModal}
                >
                    <ClearIcon
                    sx={{ color: '#777777', fontSize: '16px', ml: 0.8, mt: 0.1 }}
                    />
                </Box>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                    },
                }}
                >
                {/* Company starting point */}
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography sx={{ color: '#3F2F5B80', fontSize: '13px', verticalAlign: 'middle' }}>0 km</Typography>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: '#FF7676' }} /> {/* Solid Red */}
                        {mappedData.length > 0 && (
                            <TimelineConnector sx={{ backgroundColor: '#FF7676' }} />
                        )}
                    </TimelineSeparator>
                    
                    <TimelineContent>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#3F2F5B',  backgroundColor: '#FF767614' ,padding: '4px 8px',borderRadius: '5px'}}>Company</Typography>
                        </div>
                    </TimelineContent>
                </TimelineItem>

                {mappedData?.map((stop, index) => {
                    const isCompleted = stop.order_status === 'Completed';
                    const dotColor = isCompleted ? '#FF7676' : 'white'; // Green if completed, white if pending
                    const borderColor = isCompleted ? '#FF7676' : '#FF7676'; // Green border if completed, red if pending
                    const connectorColor = isCompleted ? '#FF7676' : '#E0E0E0'; // Gray for completed, red for pending

                    return (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent>
                                <Typography sx={{ color: '#3F2F5B80', fontSize: '13px', verticalAlign: 'middle' }}>
                                    {stop.stop_distance_km ? `${stop.stop_distance_km} km` : 'N/A'}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ 
                                    backgroundColor: dotColor, 
                                    border: `2px solid ${borderColor}` 
                                }} />
                                {index < mappedData.length - 1 && (
                                    <TimelineConnector sx={{ backgroundColor: connectorColor }} />
                                )}
                            </TimelineSeparator>
                            <TimelineContent>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '4px' }}>
                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FF767614', padding: '4px 8px', borderRadius: '5px' }}>
                                        <Typography sx={{ fontWeight: '400', color: '#3F2F5B80' }}>
                                            Stop {index + 1} 
                                            <span style={{ marginLeft: '4px', fontSize: '14px', color: '#3F2F5B' }}> - {stop.total_deliveries} deliveries</span> 
                                        </Typography> 
                                    </span>
                                    <div 
                                        style={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            fontSize: '14px', 
                                            fontWeight: '400',
                                            color: '#3F2F5B' 
                                    }}>
                                        <div
                                            style={{
                                                backgroundColor: isCompleted
                                                ? 'rgb(77, 170, 68)'
                                                : ' rgb(219, 51, 51)',
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                marginRight: '4px'
                                            }}
                                        />
                                        {isCompleted ? 'Completed' : 'Pending'}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
            </Timeline>
        </DialogContent>
    </StyledDialog>
  )
}

export default ViewModal