import { FormControl, MenuItem, TextField } from '@mui/material';

const RiderSearchBy = ({ isMdUp, rider, setRider, riderList, width='100%' }) => {
  const handleChange = (event) => {
    setRider(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <TextField
        select
        labelId="rider-search-label"
        id="rider-search-select"
        value={rider}
        label="Rider"
        onChange={handleChange}
        size="small"
        InputProps={{
          sx: {
            width: width,
            minWidth: '160px',
            height: '40px',
            background: 'transparent',
            borderRadius: '30px',
            fontSize: '14px',
            '& fieldset': {
              borderRadius: '30px',
            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid #3F2F5B',
                borderColor: '#3F2F5B !important',
              },
            },
            '&.Mui-focused': {
              '& fieldset': {
                borderColor: '#FF7676 !important',
              },
            },
            '@media (max-width: 873px)': {
              width: '100%',
            },
          },
        }}
      >
        <MenuItem value="All" sx={{ textAlign: 'center', fontSize: '14px' }}>
          All
        </MenuItem>
        {riderList.map((rider) => (
          <MenuItem
            key={rider.id}
            value={rider.name}
            sx={{ textAlign: 'center', fontSize: '14px' }}
          >
            {rider.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default RiderSearchBy; 