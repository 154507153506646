import {
  Box,
  Button,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  FormControl,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import DeliverySearchBy from './component/deliverySearchBy';
import RouteSeacrhBy from './component/routeSearchBy';
import DateRangeSelector from '../../../admin/pages/reports/components/modals/dateSelector';
import { useEffect, useRef, useState } from 'react';
import ScooterIcon from './icons/scooter.svg';
import MapIcon from './icons/map.svg';
import PrintIcon from './icons/print.svg';
import RouteModal from './component/groupRoute/routeModal';
import { useTrialGroup } from '../../customHooks/useTrialGroup';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import SearchTextField from '../../components/SearchTextField';
// import PickupFilter from '../../components/filter/PickupFilter';
import PrintLabelIcon from '@mui/icons-material/Print';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';
export const CustomTextField = styled(TextField)`
  fieldset {
    width: 100%;
  }
`;
const headerStyles = {
  fillColor: 'white', // White background color for headers
  textColor: 'black', // Black text color for headers
  fontStyle: 'bold', // Bold font style for headers
};

const cellStyles = {
  fillColor: 'white', // White background color for table cells
  textColor: 'black', // Black text color for table cells
};

const borderStyles = {
  lineWidth: 0.1, // Border line width
  lineColor: [0, 0, 0], // Black border color
};
const SPGridHeaderButton = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: !isMdUp ? '100%' : '140px',
      }}
    >
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: '100%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          py: '1px',
          px: '{ md: 3, lg: 3, sm: 2, xs: 1 }',
          textTransform: 'capitalize',
          padding: '2px 0px 2px 0px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeaderDatePicker = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: !isMdUp ? '100%' : '260px',
      }}
    >
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          width: '100%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};
const SPGridHeaderButton2 = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,

          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 5, lg: 5, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeaderButtonIcon = ({
  children,
  onClick,
  vari,
  icon,
  deliveryGroupList,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const anchorRef = useRef(null);
  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : '10%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const TrialGroupGridHeader = ({
  keyword,
  setKeyword,
  searchBy,
  setSearchBy,
  onClickFunc,
  list,
  route,
  setRoute,
  dateRange,
  setDateRange,
  startDate,
  endDate,
  dateonClick,
  setIsLoading,
  printingType,
  setPrintingType,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { datemodalOpen, setDateModalopen } = useUtility();
  const [deliveryGroupList, setDeliveryGroupList] = useState([]);
  const { show } = useSnackbar();
  const { routes } = useTrialGroup();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  function formatDateReport(date) {
    return dayjs(date).format('YYYY/MM/DD');
  }
  const handleClose = () => {
    setOpen(false);
  };
  const getDeliveryGroupLists = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/daily/delivery?delivery_method=self_pickup`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name: keyword,
        is_active: true,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
        route_name: route,
      },
    };

    try {
      const response = await axios(getConfig);
      setDeliveryGroupList(response.data.data);
      handleButtonClick();
    } catch (error) {
      console.error(error);
    }
  };
  const handlePrintLabels = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata'))?.token;
    const apiUrl = `${TENANT_URL}/deliveries/print-labels`;

    const postConfig = {
      method: 'get',
      url: apiUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name: keyword,
        is_active: true,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
        route_name: route,
        delivery_method: 'self_pickup',
      },
    };

    try {
      const response = await axios(postConfig);
      const deliveryGroupList = response.data.data;
      let zplString = ``;
      for (let i = 0; i < deliveryGroupList.length; i++) {
        const record = deliveryGroupList[i];
        const {
          name,
          address,
          driver_instruction,
          menu_plan,
          phone,
          delivery_method,
          route_area,
          route_name,
        } = record;
        zplString =
          zplString +
          `^XA
            ^MMT
            ^PW400
            ^LL400
            ^LS0
            ^FT18,75^A0N,39,38^FH^CI28^FD${name}^FS^CI27
            ^FO18,129^A0N,20,20^FH^CI28^FB360,10,0,L,0^FD${address}^FS^CI27
            ^FO18,211^A0N,20,20^FH^CI28^FB360,10,0,L,0^FD${driver_instruction}^FS^CI27
            ^FT18,289^A0N,20,20^FH^CI28^FD${menu_plan}^FS^CI27
            ^FO18,346^A0N,20,20^FH^CI28^FB360,10,0,L,0^FD${route_name}, ID ${route_area}^FS^CI27
            ^PQ1,0,1,Y
            ^XZ`;
      }

      const zpl =
        `^XA
        ~TA000
        ~JSN
        ^LT0
        ^MNW
        ^MTT
        ^PON
        ^PMN
        ^LH0,0
        ^JMA
        ^PR6,6
        ~SD25
        ^JUS
        ^LRN
        ^CI27
        ^PA0,1,1,0
        ^XZ
` + zplString;

      window?.printData(zpl, show);
    } catch (error) {
      console.error('Error Details:', error);
      if (error.response) {
        console.error('Response Error:', error.response.data);
        show(
          error.response?.data?.message || 'Unknown error occurred',
          'error'
        );
      } else {
        console.error('Network Error:', error.message);
        show('Network error occurred. Please try again later.', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const generatePDF = async () => {
    console.log(dateRange);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/all/daily/delivery?delivery_method=self_pickup`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: keyword,
          is_active: true,
          start_date: formatDate(dateRange[0]),
          end_date: formatDate(dateRange[1]),
          route_name: route,
        },
      };

      const response = await axios(getConfig);
      const deliveryGroupList = response.data.data;
      const doc = new jsPDF({
        orientation: 'landscape', // Set orientation to landscape for wider page
        unit: 'mm', // Set measurement unit to millimeters
        format: [297, 210], // Set custom width and height for the page (297mm x 210mm is A4 size landscape)
      });
      doc.text(
        `Route Name : ${[
          ...new Set(deliveryGroupList.map((item) => item.route_name)),
        ]}`,
        20,
        10
      ); // Adjust the position as needed
      doc.text(
        `Date: ${formatDateReport(dateRange[0])} - ${formatDateReport(
          dateRange[1]
        )}`,
        20,
        20
      ); // Adjust the position as needed
      autoTable(doc, {
        startY: 30, // Move the table down

        head: [['Route Id', 'Name', 'Address', 'Tel', 'Delivery Note', '']],
        body: deliveryGroupList.map((item) => [
          item.id,
          item.name,
          item.address,
          item.phone,
          item.driver_instruction,
          '',
        ]),
        theme: 'grid',
        tableWidth: 260, // Adjust table width accordingly
        headStyles: headerStyles,
        bodyStyles: cellStyles,
        footStyles: {}, // You can define styles for footers if needed
        alternateRowStyles: {}, // Styles for alternate rows if needed
        styles: borderStyles,
        columnStyles: {},
      });

      doc.save('Trail Group Report');
    } catch (error) {
      console.error(error);
    }
  };

  const anchorRef = useRef(null);

  const handleButtonClick = () => {
    anchorRef.current.click();
  };
  const printingList = [
    { id: 'usb', name: 'USB' },
    { id: 'network', name: 'Network' },
  ];
  const handleChangePrintingType = (event) => {
    setPrintingType(event.target.value);
    window.setupPrinter(show, event.target.value, setIsLoading);
  };
  return (
    <Box
      sx={{
        width: '100%',
        px: '10.4px',
        paddingBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          justifyContent: 'space-between',
          // flexWrap: 'wrap',
          // alignItems: 'center',
          // gap: '15px',
        }}
      >
        <Box
          sx={{
            display: isMdUp ? 'flex' : 'block',
            width: '100%',
            // justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <Box>
            <SearchTextField
              keyword={keyword}
              setKeyword={setKeyword}
              width="240px"
            />
          </Box>
          {/* <Box>
            <RouteSeacrhBy
              isMdUp={isMdUp}
              route={route}
              setRoute={setRoute}
              list={list}
              width="240px"
            />
          </Box> */}
          <Box>
            <DateRangeSelector
              value={dateRange}
              setValue={setDateRange}
              width={isMdDown ? '100%' : '240px'}
            />
          </Box>
          {/* <Box>
            <PickupFilter
              keyword={keyword}
              setKeyword={setKeyword}
              width={isMdDown ? '100%' : '180px'}
            />
          </Box> */}
          {/* dropdown field for selecting printing type */}
          <Box>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                select
                labelId="search-by-label"
                id="search-by-select"
                value={printingType}
                label={'Printing Type'}
                onChange={handleChangePrintingType}
                size="small"
                InputProps={{
                  sx: {
                    width: '100%',
                    minWidth: '160px',
                    height: '40px',
                    background: 'transparent',
                    borderRadius: '30px',
                    fontSize: '14px',
                    '& fieldset': {
                      borderRadius: '30px',
                    },
                    '&:hover': {
                      '& fieldset': {
                        border: '1px solid #3F2F5B',
                        borderColor: '#3F2F5B !important',
                      },
                    },
                    '&.Mui-focused': {
                      '& fieldset': {
                        borderColor: '#FF7676 !important',
                      },
                    },
                    '@media (max-width: 873px)': {
                      width: '100%',
                    },
                  },
                }}
              >
                {printingList.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    sx={{ textAlign: 'center', fontSize: '14px' }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Tooltip title={'Label Print'} placement="top" arrow>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '20px',
                  color: '#3F2F5B',
                  textTransform: 'capitalize',
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
                onClick={handlePrintLabels}
              >
                <img src={PrintIcon} />
              </Button>
            </Tooltip>
          </Box>
          <Box
            sx={{
              marginRight: '15px',
            }}
          >
            <Tooltip title={'Pdf Print'} placement="top" arrow>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '20px',
                  color: '#3F2F5B',
                  textTransform: 'capitalize',
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
                onClick={generatePDF}
              >
                <PrintLabelIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            mr: 2,
          }}
        >
          {/* <Button
            sx={{
              backgroundColor: '#FF7676',
              color: 'white',
              height: '40px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              minWidth: '90px',
              width: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#FF7676',
                color: 'white',
              },
            }}
            type="Button"
          >
            <span>Search</span>
          </Button> */}
        </Box>
      </Box>
      {open && <RouteModal open={open} handleClose={handleClose} />}
    </Box>
  );
};

export default TrialGroupGridHeader;
