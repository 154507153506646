import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';
import dayjs from 'dayjs';

const RouteHistoryContext = createContext();

export function useRouteHistory() {
  return useContext(RouteHistoryContext);
}

export const RouteHistoryProvider = ({ children }) => {
  const [routeViewDetails, setRouteViewDetails] = useState([]);
  const [routeHistoryList, setRouteHistoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [routes, setRoutes] = useState([]);
  const [cardData, setCardData] = useState({});
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [keyword, setKeyword] = useState('');
  const [route, setRoute] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [riderList, setRiderList] = useState([]);
  const [rider, setRider] = useState('');

  console.log("adasdas");
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getRouteHistoryList(currentPage);
  }, [currentPage]);
  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  } 

  const getData = () => {
    setLoading(true);
    getRouteHistoryCard();
    getRouteHistoryList(1);
    getRoutes();
    getRiderList();
    setLoading(false);
  }; 



  const getRouteHistoryById = async (data) => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const config = {
        method: 'get', 
        url: `${TENANT_URL}/order/route-history/${data.route_id}/${formatDate(data.delivery_date)}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status.');
    }
  };

  const getRiderList = async (pageNumber) => {
    console.log('SetLoading(True)');
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/delivery/boy`,
      headers: {
        Authorization: `Bearer ${token}`,
      }, 
    };
    // setLoading(true);
    try {
      const response = await axios(getConfig);
      setRiderList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    console.log('SetLoading(False)');
  };

  const getRouteHistoryList = async (pageNumber) => {
    const data = {
      page: pageNumber,
      start_date: formatDate(dateRange[0]),
      end_date: formatDate(dateRange[1]),
    };

    const queryParams = new URLSearchParams({
      ...data,
      delivery_method: 'self_pickup',
    }).toString();

    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/order/route-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios({ ...getConfig, data: data });
      let apiData = response.data.data.data ? response.data.data.data : [];
      setRouteHistoryList(apiData);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getRouteHistoryLists = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/order/route-history?${selectedFilter}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: keyword,
        route_id: route === "All" ? "" : routes.find(r => r.route_name === route)?.id || '',
        driver_name: rider === "All" ? "" : rider,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
      },
    };

    try {
      const response = await axios(getConfig);
      let apiData = response.data.data.data ? response.data.data.data : [];
      setRouteHistoryList(apiData);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (route || dateRange || keyword.length > 2 || rider) {
      getRouteHistoryLists();
    }
  }, [route, dateRange, keyword, selectedFilter, rider]);
  const getRouteHistoryCard = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/order/route-history/card`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setCardData(response.data.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getRoutes = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/route`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      let routes = response.data.data || []
      routes.unshift({ created_at: "25 Feb 2024", deleted_at: null, description: "All", id: 0, is_active: true, route_area: "All", route_name: "All", updated_at: "2024-02-26T13:38:03.000000Z" })
      setRoutes(routes);
    } catch (error) {
      console.log(error);
    }
  };

  const values = {
    routeHistoryList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    getRouteHistoryById,
    links,
    meta,
    cardData,
    routes,
    setDateRange,
    dateRange,
    setKeyword,
    keyword,
    setRoute,
    route,
    selectedFilter,
    setSelectedFilter,
    riderList,
    setRider,
    rider
  };

  return (
    <RouteHistoryContext.Provider value={values}>
      {children}
    </RouteHistoryContext.Provider>
  );
};
