import {
  Box,
  Button,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  FormControl,
  MenuItem,
} from '@mui/material';
import ViewModal from './component/modal/view';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import DateRangeSelector from '../../../admin/pages/reports/components/modals/dateSelector';
import { useEffect, useRef, useState } from 'react'; 
import RouteModal from './component/groupRoute/routeModal';
import { useRouteHistory } from '../../customHooks/useRouteHistory';
import dayjs from 'dayjs';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import SearchTextField from '../../components/SearchTextField';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';
import RouteSeacrhBy from './component/routeSearchBy';
import RiderSearchBy from './component/RiderSearchBy';

export const CustomTextField = styled(TextField)`
  fieldset {
    width: 100%;
  }
`; 

const SPGridHeaderButtonIcon = ({
  children,
  onClick,
  vari,
  icon,
  routeHistoryList,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const anchorRef = useRef(null);
  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : '10%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const RouteHistoryGridHeader = ({
  keyword,
  setKeyword,
  searchBy,
  setSearchBy,
  onClickFunc,
  list,
  route,
  setRoute,
  dateRange,
  setDateRange,
  startDate,
  endDate,
  dateonClick,
  setIsLoading,
  printingType,
  setPrintingType,
  riderList,
  rider,
  setRider,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { viewModalOpen, setViewModalOpen } = useUtility();
  const [routeHistoryList, setRouteHistoryList] = useState([]);

  const { show } = useSnackbar();

  const { routes } = useRouteHistory();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  function formatDateReport(date) {
    return dayjs(date).format('YYYY/MM/DD');
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal open={viewModalOpen} setOpen={setViewModalOpen} />
      <Box
        sx={{
          width: '100%',
          px: '10.4px',
          paddingBottom: '10px',
        }}
      >
        <Box
          sx={{
            display: isMdUp ? 'flex' : 'block',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: isMdUp ? 'flex' : 'block',
              width: '100%',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Box>
              <SearchTextField
                keyword={keyword}
                setKeyword={setKeyword}
                width="240px"
              />
            </Box>
            <Box>
              <RouteSeacrhBy
                isMdUp={isMdUp}
                route={route}
                setRoute={setRoute}
                list={routes}
                width="240px"
                label="Route"
              />
            </Box>
            <Box>
              <DateRangeSelector
                value={dateRange}
                setValue={setDateRange}
                width={isMdDown ? '100%' : '240px'}
              />
            </Box>
            <Box>
              <RiderSearchBy
                isMdUp={isMdUp}
                rider={rider}
                setRider={setRider}
                riderList={riderList}
                width="240px"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RouteHistoryGridHeader;
