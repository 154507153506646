import { Outlet } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/navbar';
import { useState } from 'react';

const Layout = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const leftSpan = 1.8;
  const rightSpan = 12 - leftSpan;
  const [showHoverSidebar,setShowHoverSidebar] = useState(true)
  const [searchVal,setSearchVal] = useState("")

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {isMdUp ? (
          <>
            <Box sx={{ position: 'absolute' }}>
              <Sidebar showHoverSidebar={showHoverSidebar} setShowHoverSidebar={setShowHoverSidebar} setSearchVal={setSearchVal}/>
            </Box>
            <Box sx={{ paddingLeft: { md: '273px' }, backgroundColor:'#FDFDFD !important' }}>
              <Box>
                <Navbar  setShowHoverSidebar={setShowHoverSidebar} setSearchVal={setSearchVal} searchVal={searchVal}/>
              </Box>
              <Box
                sx={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '93vh',
                  display: 'flex',
                  overflowY: 'auto', // Enable vertical scrolling
                }}
              >
                {/* Scrollable Outlet Container */}
                <Box
                  sx={{
                    height: 'calc(100vh - 62px)', // Adjust the height to suit your layout
                    overflowY: 'auto',
                    width: '100%',
                    boxSizing: 'border-box', // Ensures padding doesn't affect dimensions
                  }}
                >
                  <Outlet context={{ searchVal }}  />
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Grid item xs={12}>
            <Box>
              <Navbar />
            </Box>
            <Box
              sx={{
                background: 'linear-gradient(135deg, #DAC3E9, white 100%)',
                minHeight: '90vh',
                display: 'flex',
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              {/* Scrollable Outlet Container */}
              <Box
                sx={{
                  height: 'calc(100vh - 62px)', // Adjust the height to suit your layout
                  overflowY: 'auto',
                  width: '100%',
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Layout;
