import * as Yup from 'yup';

export const validationSchemas = (itemIdForUpdate) => {
  return {
    //page 1 validations
    1: Yup.object().shape({
      fullName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Only alphabetic')
        .max(30, 'Too long')
        .required('*'),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Invalid'
        )
        .required('*'),
      phone: Yup.string()
        .matches(/^(?:(?:\+?1\s*(?:[-]?\d{3})?)?(?:\d{10}))$/, 'Invalid')
        .required('*'),
      addressType: Yup.string().required('*'),
      address: Yup.string()
        .min(2, 'Too short')
        .max(150, 'Too long')
        .required('*'),
      unit: Yup.string()
        .max(10, 'Too long')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric '),
      state_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
        .max(30, 'Too long')
        .required('*'),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
        .max(30, 'Too long')
        .required('*'),
      city: Yup.string().max(30, 'Too long').required('*'),
      zip: Yup.string()
        .test('zip-validation', function (value) {
          const { country } = this.parent;

          const patterns = {
            canada: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
            usa: /^\d{5}$/,
            'united states': /^\d{5}$/,
            india: /^\d{6}$/,
            uk: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
            'united kingdom': /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
            australia: /^\d{4}$/,
            'new zealand': /^\d{4}$/,
            pakistan: /^\d{5}$/,
          };
          const errorMessage = `Invalid ${country} zip format`;

          if (country) {
            const patternCountry = patterns[country.toLowerCase()];
            if (patternCountry) {
              const isValid = patternCountry.test(value);
              if (!isValid) {
                return this.createError({ message: errorMessage });
              }
              return true;
            }
          }

          const defaultPattern =
            /^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i;
          const isValidDefault = defaultPattern.test(value);
          if (!isValidDefault) {
            return this.createError({ message: 'Invalid zip format' });
          }
          return true;
        })
        .required('*'),
      instruction: Yup.string()
        .max(160, 'Too long')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric ')
        .required('*'),
      password: itemIdForUpdate
        ? Yup.string().notRequired()
        : Yup.string().min(6, 'At least 6 characters').required('*'),
    }),

    //page 2 validations
    2: Yup.object().shape({
      date: Yup.string().required('*'),
      deliveryTime: Yup.string().required('*'),
      route: Yup.string().required('*'),
      payment: Yup.number()
        .typeError('Invalid')
        .positive('Greater than zero')
        .test('decimal-places', 'Only numeric and two decimal', (value) =>
          /^[0-9]+(\.[0-9]{1,2})?$/.test(value)
        )
        .required('*'),
      category: Yup.string().required('*'),
      meal: Yup.object().required('*'),
      schedule: Yup.array()
        .min(1, 'Please select at least one day')
        .required('*'),
    }),

    //page 3 validations
    3: Yup.object().shape({
      paymentStatus: Yup.string().required('*'),
      paymentOption: Yup.string(),
      amount: Yup.string().required('*'),

      cardName: Yup.string().when('paymentOption', {
        is: (val) => {
          return val == 'Card';
        },
        then: (res) => {
          return Yup.string().required('*');
        },
        otherwise: (res) => {
          return Yup.string().notRequired();
        },
      }),
      cardNo: Yup.string().when('paymentOption', {
        is: (val) => {
          return val == 'Card';
        },
        then: (res) => {
          return Yup.string().required('*');
        },
        otherwise: (res) => {
          return Yup.string().notRequired();
        },
      }),
      cvv: Yup.string().when('paymentOption', {
        is: (val) => {
          return val == 'Card';
        },
        then: (res) => {
          return Yup.string().required('*');
        },
        otherwise: (res) => {
          return Yup.string().notRequired();
        },
      }),
      expiryDate: Yup.string().when('paymentOption', {
        is: (val) => {
          return val == 'Card';
        },
        then: (res) => {
          return Yup.string().required('*');
        },
        otherwise: (res) => {
          return Yup.string().notRequired();
        },
      }),
      billingAddress: Yup.string().when('paymentOption', {
        is: (val) => {
          return val == 'Card';
        },
        then: (res) => {
          return Yup.string().required('*');
        },
        otherwise: (res) => {
          return Yup.string().notRequired();
        },
      }),
    }),
  };
};
