import { Box, Paper, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressCard = ({ amount, text, date }) => {
  return (
    <Paper
      sx={{
        border: '1px solid #F5F6F8',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
        boxShadow: '0px 4px 4px 0px #00000026',
        borderRadius: '15px',
        minHeight: '120px',
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '22px',
              fontWeight: '500',
              lineHeight: '27.72px',
              textAlign: 'left',
            }}
          >
            {text}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: '22px',
            fontWeight: '500',
            lineHeight: '27.72px',
            textAlign: 'left',
          }}
        >
          <b>{amount}%</b>
        </Typography>
      </Box>
      <Box width={'100%'}>
        <LinearProgress
          variant="determinate"
          value={amount}
          sx={{
            backgroundColor: '#FFF',
            borderRadius: '50px',
            height: '12px',
            '& .MuiLinearProgress-bar': {
              backgroundColor:
                'linear-gradient(90deg, #FF7C52 0%, #FF61A3 142.72%)',
              borderRadius: '50px',
              height: '12px',
            },
          }}
        />
      </Box>
      <Typography variant="subtitle2" sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '17.57px', textAlign: 'right', color: '#707070' }}>  {date} </Typography>
    </Paper>
  );
};

export default ProgressCard;
