import React, { useState, useEffect } from 'react';
import {
  Box,
  InputLabel,
  List,
  ListItem,
  ClickAwayListener,
  styled,
  TextField,
} from '@mui/material';

const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input': {
    border: 'none',
    borderRadius: '10px',
    width: '100%',
    padding: '8.5px 14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});
const AutocompleteAddressInput = ({
  field,
  form,
  placeholder,
  label,
  height = '9px',
  setFieldValue,
  ...props
}) => {
  const [query, setQuery] = useState(field.value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1);
  const [listVisible, setListVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const apiKey = process.env.REACT_APP_PLACES_API_KEY;

  useEffect(() => {
    if (!query || query.length < 3 || !listVisible) {
      setSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          query
        )}.json?access_token=${apiKey}`
      );
      const data = await response.json();
      setSuggestions(data.features);
    };

    fetchSuggestions();

    return () => setSuggestions([]);
  }, [query, apiKey, listVisible]);

  const handleSelect = (suggestion) => {
    setListVisible(false);
    setQuery(suggestion.place_name);
    setSelected(true);
    // Set coordinates
    if (suggestion.center) {
      const [longitude, latitude] = suggestion.center;
      form.setFieldValue('longitude', longitude);
      form.setFieldValue('latitude', latitude);
      
      // Update billing address coordinates
      form.setFieldValue('billing_address.longitude', longitude);
      form.setFieldValue('billing_address.latitude', latitude);
    }

    // Parse context array for address components
    const context = suggestion.context || [];
    let city, state, zipcode, country, country_name, state_name;

    context.forEach((item) => {
      if (item.id.startsWith('place.')) {
        city = item.text; // City name
      } else if (item.id.startsWith('region.')) {
        state_name = item.text; // State/Province code
        state = item.short_code.slice(-2); // State/Province code
      } else if (item.id.startsWith('postcode.')) {
        zipcode = item.short_code; // Zip code
      } else if (item.id.startsWith('country.')) {
        country_name = item.text; // Country Name
        country = item.short_code.substring(0, 2).toUpperCase(); // Country Code
      }
    });

    // Update form values
    form.setFieldValue('address', suggestion.place_name);
    form.setFieldValue('billing_address.address', suggestion.place_name);

    if (city) {
      form.setFieldValue('city', city);
      form.setFieldValue('billing_address.city', city);
    }
    
    if (state) {
      form.setFieldValue('state', state);
      form.setFieldValue('billing_address.state', state);
    }
    
    if (state_name) {
      form.setFieldValue('state_name', state_name);
      form.setFieldValue('billing_address.state_name', state_name);
    }
    
    if (zipcode) {
      form.setFieldValue('zip', zipcode);
      form.setFieldValue('billing_address.zip', zipcode);
    }
    
    if (country) {
      form.setFieldValue('country', country);
      form.setFieldValue('billing_address.country', country);
    }
    if (country_name) {
      form.setFieldValue('country_name', country_name);
      form.setFieldValue('billing_address.country_name', country_name);
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    form.setFieldValue('address', e.target.value);
    form.setFieldValue('billing_address.address', e.target.value);
    setListVisible(true);
    setSelected(false);
  };

  const handleBlur = (e) => {
    if (!selected) {
      setQuery('');
      form.setFieldValue('address', '');
      form.setFieldValue('billing_address.address', '');
    } else {
      form.setFieldValue('address', e.target.value);
      form.setFieldValue('billing_address.address', e.target.value);
    }
    
    // Only set touched if field exists
    // if (field && field.name) {
    //   form.setFieldTouched(field.name, true);
    // }
    
    setListVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : 0
      );
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (focusedSuggestionIndex >= 0) {
        handleSelect(suggestions[focusedSuggestionIndex]);
      }
    }
  };
  return (
    <ClickAwayListener onClickAway={() => setListVisible(false)}>
      <Box position={'relative'}>
        <InputLabel
          sx={{
            fontSize: '1rem',
            lineHeight: '1.5',
            letterSpacing: '0.00938em',
            marginBottom: '7px',
            fontWeight: '500',
          }}
        >
          {label}
          {/* <ValidationError form={form} field={field} /> */}
        </InputLabel>
        <StyledTextField
          {...field}
          {...props}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoComplete="nope"
        />
        {listVisible && suggestions.length > 0 && (
          <List
            sx={{
              position: 'absolute',
              width: '99%',
              maxHeight: 150,
              overflow: 'auto',
              zIndex: 999,
              background: 'white',
              borderRadius: '0 0 10px 10px',
              boxShadow: '0px 4px 4px 0px #00000026',
              m: 'auto',
              marginTop: '2px',
              left: 0,
              right: 0,
            }}
          >
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={suggestion.id}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(suggestion);
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f0f0f0' },
                  backgroundColor:
                    index === focusedSuggestionIndex ? '#f0f0f0' : 'white',
                }}
              >
                {suggestion.place_name}
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default AutocompleteAddressInput;
