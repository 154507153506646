import logo from '../../../icons/Fork.svg';

const basicButtonStyles = {
  borderRadius: '30px',
  my: 1,
  height: '45px',
};

export const buttonStyles = {
  ...basicButtonStyles,
};

export const selectedButtonStyles = {
  ...basicButtonStyles,
  background: 'white',
  '&:hover': {
    backgroundColor: 'white',
  },
};

const basicButtonTextStyles = {
  ml: 1,
  mt: 0.7,
};

export const buttonTextStyles = {
  ...basicButtonTextStyles,
  color: 'white',
};

export const selectedButtonTextStyles = {
  ...basicButtonTextStyles,
  color: 'white',
};

export const selectedIconStyles = {
  fill: '#646262',
  width: '24px',
};

export const drawerListStyles = { width: '90%', margin: '20px auto', borderRadius: '10px' };
export const sideBarLogo = logo;
export const sidebarLogoStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const sidebarLogoImgStyles = {
  width: '150px',
  height: '150px',
  objectFit: 'contain',
};
