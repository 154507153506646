import { Box, Typography } from '@mui/material';
import React from 'react';

const Wallet = ({ title, amount, image }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '10px',
          background: ' #FFFFFF1A',
          borderRadius: '8px',
          boxShadow: '0px 4px 4px 0px #00000026',
          border: 'solid 1px white',
          alignItems: 'center',
          height: '100%',
          gap: '12px',
        }}
      >
        <img
          src={require(`../../assets/${image}.png`)}
          style={{
            width: '42px',
            height: '42px',
          }}
        />
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '0.3px',
            textAlign: 'left',
          }}
        >
          {title ? title : 'N/A'}
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            marginLeft: 'auto',
            color: '#ff6b00',
          }}
        >
          ${amount ? `${parseFloat(amount).toFixed(2)}` : '$0.00'}
        </Typography>
      </Box>
    </>
  );
};

export default Wallet;
