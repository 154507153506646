import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  styled,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomerModalInputText from '../CustomerModalInputs/CustomerModalInputText';
import CustomerModalInputSelect2 from '../CustomerModalInputs/CustomerModalInputSelect2';
import PaymentModalInputTextArea from '../paymentModalIInputs/paymentModalInputDescription';
import AutocompleteAddressInput from '../../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import CancelIcon from '@mui/icons-material/Cancel';
import { useProfile } from '../../../../customHooks/useProfile';

import axios from 'axios';
import { TENANT_URL } from '../../../../../config';
import { useSnackbar } from '../../../../../components/utils/snackbarProvider/SnackbarContextProvider';

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabetic')
    .max(30, 'Too long')
    .required('*'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invalid'
    )
    .required('*'),
  phone: Yup.string()
    .matches(/^(?:(?:\+?1\s*(?:[-]?\d{3})?)?(?:\d{10}))$/, 'Invalid')
    .required('*'),
  addressType: Yup.string().required('*'),
  unit: Yup.string().required('*'),
  address: Yup.string().min(2, 'Too short').max(150, 'Too long').required('*'),
  state_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
    .max(30, 'Too long')
    .required('*'),
  state: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
    .max(30, 'Too long')
    .required('*'),
  city: Yup.string().max(30, 'Too long').required('*'),
  zip: Yup.string()
    .test('zip-validation', function (value) {
      const { country } = this.parent;

      const patterns = {
        canada: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
        usa: /^\d{5}$/,
        'united states': /^\d{5}$/,
        india: /^\d{6}$/,
        uk: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
        'united kingdom': /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
        australia: /^\d{4}$/,
        'new zealand': /^\d{4}$/,
        pakistan: /^\d{5}$/,
      };
      const errorMessage = `Invalid ${country} zip format`;

      if (country) {
        const patternCountry = patterns[country.toLowerCase()];
        if (patternCountry) {
          const isValid = patternCountry.test(value);
          if (!isValid) {
            return this.createError({ message: errorMessage });
          }
          return true;
        }
      }

      const defaultPattern =
        /^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i;
      const isValidDefault = defaultPattern.test(value);
      if (!isValidDefault) {
        return this.createError({ message: 'Invalid zip format' });
      }
      return true;
    })
    .required('*'),
});
const StyledDialog = styled(Dialog)(({}) => ({
  '& .MuiDialog-paper': {
    width: 900,
    borderRadius: '15px',
    height: 660,
    minWidth: 708,
  },

  '& .MuiDialogTitle-root': {
    padding: '15px 24px 0',
  },
  '& .MuiDialogActions-root': {
    padding: '0 24px 15px',
  },
}));
function updateProfileCardModal({ open, setOpen }) {
  const { show } = useSnackbar();
  const { loading, currentCustomer, currentCustomerData, updateCustomer ,updateCustomerProfile, getProfileData} =
    useProfile();

  const addressTypeList = ['Home', 'Office', 'Other'];
  const initialValues = {
    fullName: currentCustomerData ? currentCustomerData.name : '',
    email: currentCustomerData ? currentCustomerData.email : '',
    phone: currentCustomerData ? currentCustomerData.phone : '',
    addressType: currentCustomerData
      ? currentCustomerData.address_type
      : 'Home',
    address: currentCustomerData ? currentCustomerData.address : '',
    state: currentCustomerData ? currentCustomerData.state : '',
    state_name: currentCustomerData ? currentCustomerData.state_name : '',
    country: currentCustomerData ? currentCustomerData.country : '',
    country_name: currentCustomerData ? currentCustomerData.country_name : '',
    city: currentCustomerData ? currentCustomerData.city : '',
    zip: currentCustomerData ? currentCustomerData.zip_code : '',
    unit: currentCustomerData ? currentCustomerData.unit : '',
    latitude: currentCustomerData ? currentCustomerData.latitude : '',
    longitude: currentCustomerData ? currentCustomerData.longitude : '',
  };
  const handleSubmit = async (values, actions) => {
    var model = {
      name: values.fullName,
      email: values.email,
      phone: values.phone,
      address_type: values.addressType,
      address_line_1: values.address,
      state: values.state,
      state_name: values.state_name,
      country: values.country,
      country_name: values.country_name,
      city: values.city,
      zip_code: values.zip,
      latitude: values.latitude,
      longitude: values.longitude,
      unit: values.unit,
    };

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/customer/app/profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: model });
      console.log("response:",response);
      show('Customer Updated Successfully');
      handleClose();
      window.location.reload
      // await getProfileData();
    } catch (error) {
      show(error.response.data.message, 'error');
      console.log(error, error.response.data.message);
    }

    // updateCustomerProfile(currentCustomer, model, handleClose);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        submitForm,
        resetForm,
      }) => {
        console.log(values, errors);
        return (
          <StyledDialog open={open && !loading} onClose={handleClose}>
            <DialogTitle>
              <Box sx={{ display: 'flex', fontWeight: '600' }}>
                Update Customer Data
                <Box
                  sx={{
                    ml: 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={handleClose}
                >
                  <CancelIcon
                    alt="closeIcon"
                    style={{ fontSize: '35px', color: '#ff7676' }}
                    sx={{
                      transition: 'box-shadow 0.3s',
                      borderRadius: '50px',
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                        WebkitBoxShadow:
                          '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                        MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                      },
                    }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent
              border={1}
              sx={{
                padding: '0 30px 15px ',
              }}
            >
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="fullName"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    enableReinitialize={true}
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="phone"
                    label="Phone Number"
                    placeholder="+11234567890"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '50px',
                      },
                    }}
                    name="addressType"
                    label="Address Type"
                    list={addressTypeList}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputSelect2}
                    fieldValue={values.addressType}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="address"
                    label="Address"
                    placeholder="Enter Address"
                    component={AutocompleteAddressInput}
                  />
                  <input type="hidden" name="longitude" />
                  <input type="hidden" name="latitude" />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="unit"
                    label={
                      <InputLabel
                        sx={{
                          margin: '4px 0px -2px',
                        }}
                      >
                        Unit
                      </InputLabel>
                    }
                    placeholder="1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="state_name"
                    label="State"
                    placeholder="State"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="city"
                    label="City"
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Field
                    InputformikProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      },
                    }}
                    name="zip"
                    label="Zip"
                    placeholder="A1A 1A1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CustomerModalInputText}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                gap: '24px',
              }}
            >
              <Button variant="unfilled" onClick={resetForm} type="reset">
                Reset
              </Button>
              <Button variant="filled" type="button" onClick={submitForm}>
                Update
              </Button>
            </DialogActions>
          </StyledDialog>
        );
      }}
    </Formik>
  );
}

export default updateProfileCardModal;
