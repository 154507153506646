import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const UpdateOverviewModal = ({
  open,
  handleClose,
  newPlanData,
  addressData,
  newAdditionalData,
  newAdditionalDataString,
  validVoucher,
  subscription,
  discountCoupan,
}) => {
  const navigate = useNavigate(); // Initialize navigate hook

  const newPlan = newPlanData;
  const newAdditional = newAdditionalData;
  const address = addressData;

  const [mealFor, setMealFor] = useState('home_delivery');
  const [orderFor, setOrderFor] = useState('breakfast'); 

  const handleCheckout = () => {
    const newCheckoutData = {
      customData: newAdditional,
      menu_plan_id: newPlan.id,      
      delivery_timeslot: orderFor,
      preferred_delivery_method: mealFor,
      voucher_code: validVoucher,
      billing_address:address
    };

    const oldCheckoutData = {
      newPlan,
      newAdditional,
      subscription,
      discountCoupan,
      orderFor,
      mealFor,
    };

    navigate('/customer/order-checkout', { state: { newCheckoutData, oldCheckoutData } });
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Overview
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 16,
            top: 12,
            width: 30,
            height: 30,
            backgroundColor: '#f0645b',
          }}
          sx={{
            color: '#ffffff',
          }}
        >
          <CloseIcon color="#ffffff" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table
            sx={{
              '@media (max-width:767px)': {
                minWidth: 700,
                overflowX: 'auto',
              },
            }}
          >
            <TableHead
              sx={{
                backgroundColor: '#F2F4F5',
                textTransform: 'uppercase',
                '& .MuiTableCell-head': {
                  padding: '7px 15px',
                },
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    color: '#475156',
                    fontSize: '12px',
                  }}
                >
                  Items
                </TableCell>
                <TableCell
                  sx={{
                    color: '#475156',
                    fontSize: '12px',
                  }}
                >
                  Addon
                </TableCell>
                <TableCell
                  sx={{
                    color: '#475156',
                    fontSize: '12px',
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    color: '#475156',
                    fontSize: '12px',
                  }}
                >
                  Additional Items
                </TableCell>
                <TableCell
                  sx={{
                    color: '#475156',
                    fontSize: '12px',
                  }}
                >
                  Sub-Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-body': {
                  padding: '15px 15px',
                  borderBottom: 'none',
                },
              }}
            >
              <TableRow>
                <TableCell width={280}>
                  <Box display="flex" flexDirection="row" gap="6px">
                    <Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap="6px"
                      >
                        <Box>
                          <Typography
                            variant="h1"
                            fontSize={15}
                            color="#000000"
                            fontWeight="bold"
                            marginBottom="2px"
                          >
                            {newPlan?.name}
                          </Typography>
                          <Typography
                            variant="h1"
                            fontSize={12}
                            color="#000000"
                            fontWeight="400"
                            lineHeight="18px"
                          >
                            {newPlan?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell color="#4F4D55">{newPlan?.addons}</TableCell>
                <TableCell>${newPlan?.plan_price}</TableCell>
                <TableCell>
                  ${(newAdditional?.total_price || 0).toFixed(2)}
                </TableCell>
                <TableCell>
                  $
                  {(
                    Number(newPlan?.plan_price || 0) +
                    Number((newAdditional?.total_price || 0).toFixed(2))
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  position: 'relative',
                  top: '-18px',
                }}
              >
                <TableCell colSpan={5}>
                  {/* <Box
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize={12}
                      color="#000000"
                      fontWeight="400"
                    >
                      {newPlan?.food_items || '-'}
                    </Typography>
                  </Box> */}
                  <Box
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize={14}
                      color="#000000"
                      fontWeight="700"
                    >
                      Additional Items
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize={12}
                      color="#000000"
                      fontWeight="400"
                    >
                      {newAdditionalDataString?.items || '-'}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="6px"
                  >
                    <Typography
                      variant="h1"
                      fontSize={11}
                      color="#191C1F"
                      fontWeight="400"
                    >
                      Order for: 
                    </Typography>
                    <RadioGroup
                      row
                      value={orderFor}
                      onChange={(event) => setOrderFor(event.target.value)}
                    >
                      <FormControlLabel
                        value="breakfast"
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '18px', // Adjust the size of the radio circle
                              },
                            }}
                          />
                        }
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '14px', // Adjust the font size of the label
                          },
                        }}
                        label="Breakfast"
                      />
                      <FormControlLabel
                        value="lunch"
                        label="Lunch"
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '18px', // Adjust the size of the radio circle
                              },
                            }}
                          />
                        }
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '14px', // Adjust the font size of the label
                          },
                        }}
                      />
                      <FormControlLabel
                        value="dinner"
                        label="Dinner"
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '18px', // Adjust the size of the radio circle
                              },
                            }}
                          />
                        }
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '14px', // Adjust the font size of the label
                          },
                        }}
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="6px"
                  >
                    <Typography
                      variant="h1"
                      fontSize={11}
                      color="#191C1F"
                      fontWeight="400"
                    >
                      Meal for:
                    </Typography>
                    <RadioGroup
                      row
                      value={mealFor} // Bind value to state
                      onChange={(event) => setMealFor(event.target.value)} // Update state on change
                    >
                      <FormControlLabel
                        value="home_delivery"
                        label="Delivery"
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '18px', // Adjust the size of the radio circle
                              },
                            }}
                          />
                        }
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '14px', // Adjust the font size of the label
                          },
                        }}
                      />
                      <FormControlLabel
                        value="self_pickup"
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '18px', // Adjust the size of the radio circle
                              },
                            }}
                          />
                        }
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '14px', // Adjust the font size of the label
                          },
                        }}
                        label="Pickup"
                      />
                    </RadioGroup>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ gap: '15px', marginBottom: 1 }}>
        <Button
          onClick={handleCheckout}
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#F0645B' }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOverviewModal;
