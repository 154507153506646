import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './TrialComponent.css';
import { Button, Typography } from '@mui/material';
import SliderImg from '../../assets/images/slider.png';
import CircleImg from '../../assets/images/circle.png';
import { TENANT_URL } from '../../../../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function TrialComponent() {
  // const [itemsImage, setItemImage] = useState([]);
  const [trialMeals, setTrialMeals] = useState([]);
  const navigate = useNavigate();
  
  // const getItemsImage = async () => {
  //   try {
  //     const response = await axios.get(`${TENANT_URL}/api/items-images`);
  //     const images = response.data.data || []; // Default to an empty array
  //     console.log('Fetched images:', images);
  //     setItemImage(images);
  //   } catch (error) {
  //     console.error('Error fetching images:', error);
  //     setItemImage([]); // Fallback to an empty array
  //   }
  // };

  const registerCart = async () => {
    let cartDetails = localStorage.getItem('cartID');
    if (!cartDetails) {
      try {
        // Fetch meal plan details
        const config = {
          method: 'post',
          url: `${TENANT_URL}/api/cart/create`,
        };
        const response = await axios(config);
        localStorage.setItem('cartID', response.data.cart_id);
        localStorage.setItem('deliveryMethod', 'home_delivery');
      } catch (error) {
        setError('Failed to load meal plan details.');
      }
    }
  }

  const getTrialMeals = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/trial-items`,
    };
    try {
      const response = await axios(config);
      setTrialMeals(response.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const handleAddToCart = async (id) => {
    let cartID = localStorage.getItem('cartID');
    if (!cartID) {
      await registerCart();
    }
    const data = {
      cart_id: localStorage.getItem('cartID'),
      trial_item_id: id,
    };
    const getConfig = {
      method: 'post',
      url: `${TENANT_URL}/api/cart/add-item`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios({ ...getConfig, data: data });
      navigate('/cart');
    } catch (error) {
      console.log('Error adding to cart:', error);
    }
  };

  useEffect(() => {
    // getItemsImage();
    getTrialMeals();
  }, []);

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      pagination={{ clickable: true }}
      className="swNews"
    >
      {Object.entries(trialMeals).map(([key, item], i) => (
        <SwiperSlide key={i}>
          <div className="align-items-center position-relative grandparent" style={{  }}>
            <div className="col-lg-3">
              <Typography
                variant="h4"
                lineHeight="40px"
                sx={{
                  fontSize: { xs: '28px', lg: '32px' },
                  fontWeight: 700,
                  letterSpacing: '2%',
                  color: '#ffffff',
                  mb: { xs: 0, lg: 2 },
                  fontFamily: 'Outfit !important',
                }}
              >
                Enjoy Free Meals On An Exclusive Trial
              </Typography>
              <Typography
                variant="h4"
                lineHeight="40px"
                sx={{
                  textTransform: 'capitalize',
                  fontSize: { xs: '22px', lg: '26px' },
                  fontWeight: 600,
                  letterSpacing: '2%',
                  color: '#ffffff',
                  mb: { xs: 0, lg: 2 },
                  fontFamily: 'Outfit !important',
                }}
              >
                {item.name}
              </Typography>
              <Typography
                variant="h4"
                lineHeight="20px"
                sx={{
                  fontSize: { xs: '12px', lg: '17px' },
                  fontWeight: 500,
                  color: '#ffffff',
                  mb:5 ,
                  fontFamily: 'Outfit !important',
                }}
              >
                {item.description}
              </Typography>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#FACD2D',
                  borderRadius: 100,
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  color: '#000000',
                  height: 'auto',
                  padding: '13px 26px',
                  fontFamily: 'Outfit !important',
                  '&:hover': {
                    backgroundColor: '#FACD2D',
                    boxShadow: 'none',
                    opacity: 0.5,
                  },
                }}
                onClick={() => handleAddToCart(item.id)}
                >
                Add to cart
              </Button>
            </div> 
            <div  className="col-lg-7 text-center position-relative image-container-parent">
              {/* Outer glowing ring */}
              <div className="glowing-ring">
                {/* Inner gradient background */}
                <div className="inner-gradient"></div>
              </div>

              {/* Foreground image */}
              <img src={item.image_url} alt="" className="image-slider-trial" />
            </div>

            <img
              src={CircleImg}
              alt="Decorative Circle"
              className='Decorative-Circle'
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
