import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const CampaignContext = createContext();

export function useCampaign() {
  return useContext(CampaignContext);
}

export const CampaignProvider = ({ children }) => {
  const [campaignList, setCampaignList] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { show } = useSnackbar();
  const [selectData, setSelectData] = useState([]);
  const [cardData, setCardData] = useState({});
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const searchTimeout = useRef(null);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getCampaignList();
    await getCardData();
    await getSelectData();
  };
  useEffect(() => {
    if (currentPage !== 0) getCampaignList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      getSearchedCampaignList(1);
    }, 500);
  }, [keyword]);

  const getSearchedCampaignList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/food/campaign?title=${keyword}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
      },
    };
    try {
      const response = await axios(getConfig);
      setCampaignList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getCampaignList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/food/campaign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
      },
    };
    try {
      const response = await axios(getConfig);
      setCampaignList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getSingleCampaign = async (id) => {
    setLoading(true);

    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/food/campaign/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      console.log(
        '🚀 ~ file: useCampaign.jsx:75 ~ getSingleCampaign ~ response:',
        response.data
      );

      setCampaignData(response.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/campaign/card`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setCardData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSelectData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/active/route/multi/select`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setSelectData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addNewCampaign = async (
    title,
    reciever_type,
    message,
    schedule_date,
    id,
    uploadedImage
  ) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('receivers', JSON.stringify(reciever_type));
    formData.append('message', message);
    formData.append('schedule_date', schedule_date);
    if (uploadedImage) {
      formData.append('image', uploadedImage);
    }

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: id
          ? `${TENANT_URL}/food/campaign/${id}?_method=put`
          : `${TENANT_URL}/food/campaign`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios({ ...addConfig, data: formData });
      show('Campaign Added Successfully');
      getData();
      setLoading(false);
    } catch (error) {
      show("Error: Couldn't Add Campaign", 'error');
      console.log(error);
      setLoading(false);
    }
  };

  const deleteCampaign = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/food/campaign/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getCampaignList();
      await getCardData();
      show(response.data.message);
      getData();
      setLoading(false);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const values = {
    campaignList,
    loading,
    cardData,
    selectData,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    addNewCampaign,
    deleteCampaign,
    getSingleCampaign,
    campaignData,
    keyword,
    setKeyword,
  };

  return (
    <CampaignContext.Provider value={values}>
      {children}
    </CampaignContext.Provider>
  );
};
