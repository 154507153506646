import { Image } from '@mui/icons-material';
import TrialGroupActionColumn from './trialGroupActionColumn';

const TrialGroupColumns = [
 
  {
    field: 'name',
    headerName: 'Trial Name',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.value}</div>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.value}</div>
    ),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerClassName: 'header-cell',
    align: 'left',
    headerAlign: 'left',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px', textAlign: 'left' }}>
        ${params.value}
      </div>
    ),
  },
   {
    field: 'customer',
    headerName: 'Customer',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    fontFamily: 'Outfit',
    minWidth: 100,
    renderCell: (params) => (
      <div
        style={{
          fontSize: '13px',
          fontFamily: 'Outfit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1597645587822-e99fa5d45d25"
          alt=""
          style={{ width: 40, height: 40, borderRadius: '50%' }}
        />
        {params.row.name}
      </div>
    ),
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px', fontFamily: 'Outfit' }}>
        {params.row.subscription_start_date}
      </div>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone No.',
    minWidth: 80,
    flex:1,headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 70,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return <TrialGroupActionColumn row={params.row}  />;
    },
  },
];

export default TrialGroupColumns;
