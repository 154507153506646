import { Box, Paper, Typography } from '@mui/material';

const TopCards = ({ imageWidth, imageSrc, imageAlt, amount, text, color, bgColor = 'transparent' }) => {
  return (
    <Paper
      sx={{
        border: `1px solid ${color}`,
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
        boxShadow: '0px 4px 4px 0px #00000026',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        minHeight: '93px',
        padding: '15px',
        width: { md: '100%', xs: '100%' },
        gap: '25px',
      }}
    >
      {bgColor !== 'transparent' && (
        <div style={{
          width: '51px',
          height: '51px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: bgColor,
          borderRadius: '50%',
        }}>
          <img
            style={{
              width: '32px',
              height: '32px',
            }}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      )}
      {bgColor === 'transparent' && <img
        style={{
          width: '51px',
          height: '51px',
        }}
        src={imageSrc}
        alt={imageAlt}
      />}
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: '22px',
            fontWeight: '500',
            lineHeight: '27.72px',
            textAlign: 'left',
          }}
        >
          <b>{amount}</b>
        </Typography>
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '17.64px',
              textAlign: 'left',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TopCards;
