import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  CircularProgress,
  Select,
  TextareaAutosize,
  TextField,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import { ReactComponent as HouseIcon } from './asstes/House.svg';
import { ReactComponent as ArrowRightIcon } from './asstes/arrowright.svg';
import { ReactComponent as CreditIcon } from './asstes/credit.svg';
import Checkbox from '@mui/material/Checkbox';
import Tiffin_Img from './asstes/tiffin.jpg';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import AfterpayLogo from './asstes/brand-afterpay-svgrepo-com.svg';
import MastercardLogo from './asstes/mastercard.svg';
import GooglePayLogo from './asstes/google-pay.svg';
import { Field, Form, Formik } from 'formik';
import AutocompleteAddressInput from './components/autocompleteAddressInput/autocompleteAddressInput';
import * as Yup from 'yup';

import { ServiceFooter } from '../serviceLandingPage/servicefooter';
import { ServiceNavbar } from '../serviceLandingPage/serviceNarbar';

const inpuStyle = {
  width: '100%',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#EBEBEC',
    fontFamily: 'Inter !important',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: '1em',
  },
};

const selectStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiSelect-select': {
    padding: '13px 14px',
    fontSize: '14px',
  },
};

// Add validation schemas
const addressValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  address: Yup.string().required('Address is required'),
  address_type: Yup.string().required('Address type is required'),
  unit: Yup.string().required('Unit number is required'),
  state: Yup.string().required('State/Region is required'),
  city: Yup.string().required('City is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});

const Cart = () => {
  const [cartID, setCartID] = useState(localStorage.getItem('cartID'));
  const [cartDetails, setCartDetails] = useState({});
  const [cartTaxesDetails, setCartTaxesDetails] = useState([]);

  const [additinaolItems, setAdditinaolItems] = useState('');
  const [additinaolItemsTotals, setAdditinaolItemsTotals] = useState(0);

  const [deliveryMethod, setDeliveryMethod] = useState('');

  const navigate = useNavigate();
  const [isSdkInitialized, setIsSdkInitialized] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('')
 
  const getCartID = () => {
    if (!cartID) return;

    if (!!cartID) {
      setCartID(cartID);
      setDeliveryMethod(localStorage.getItem('deliveryMethod'));
    } else {
      navigate('/');
    }
  };

  const getCartDetails = async () => {
    if (!cartID) return;

    try {
      // Fetch meal plan details
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart?cart_id=${cartID}`,
      };
      const response = await axios(config);
      console.log(response.data.cart.items.length);
      if (response.data && response.data.cart.items.length > 0) {
        setCartDetails(response.data);
        getCartTaxes();

        setAdditinaolItems(localStorage.getItem('additinoalitems') || '');
        setAdditinaolItemsTotals(
          localStorage.getItem('additinoalitemTotal') || 0
        );
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  // const placeOrder = () => {
  //   navigate('/checkout');
  // };

  const getCartTaxes = async () => {
    if (!cartID) return;
    
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart/taxes?cart_id=${cartID}&preferred_delivery_method=${deliveryMethod}`,
      };
      const response = await axios(config);
      setCartTaxesDetails(response.data.taxes);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  useEffect(() => {
    document.body.classList.add('customPage');
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);

  useEffect(() => {
    if (!cartID) return;
    getCartID();
  }, []);

  useEffect(() => {
    if (!cartID) return;
    getCartDetails();
  }, [cartID, deliveryMethod]);

  const paymentMethods = [
    {
      name: 'Visa',
      icon: (
        <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" width="40"/>
      ),
    },
    {
      name: 'Mastercard',
      icon: (
        <img src={MastercardLogo} alt="Mastercard"  width="30"  />
      ),
    },
    {
      name: 'Apple Pay',
      icon: (
        <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple Pay" width="30" height={30} />

      ),
    },
    {
      name: 'Google Pay',
      icon: (
        <img src={GooglePayLogo} alt="Google Pay" width="30"/>
      ),
    },
    {
      name: 'AfterPay',
      icon: (
        <img src={AfterpayLogo} alt="Afterpay" width="30"/>
      ),
    },
  ];

  const [checked, setChecked] = useState(true);
  const [driverInstructions, setDriverInstructions] = useState('');

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };


  const handleBillingChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      billing_address: {
        ...prev.billing_address,
        [field]: event.target.value
      }
    }));
  };

  const handleShippingChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      shipping_address: {
        ...prev.shipping_address,
        [field]: event.target.value
      }
    }));
  };

  const [formData, setFormData] = useState({
    cart_id: localStorage.getItem('cartID') || '',
    billing_address: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
      country_name: '',
      unit: '',
      state: '',
      state_name: '',
      address_type: '',
      city: '',
      address: '',
      address_type: '',
      zip: '',
      latitude: '',
      longitude: '',
    },
    shipping_address: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
      country_name: '',
      unit: '',
      state: '',
      state_name: '',
      address_type: '',
      city: '',
      address_type: '',
      address: '',
      zip: '',
      latitude: '',
      longitude: '',
    },
    payment: {
      nameOnCard: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    },
  });
  const [sessionToken, setSessionToken] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [merchantSiteId, setMerchantSiteId] = useState('');
  const [gatewayError, setGatewayError] = useState('');
  const [placeOrderError, setPlaceOrderError] = useState('');

  // Add validation state
  const [billingErrors, setBillingErrors] = useState({});
  const [shippingErrors, setShippingErrors] = useState({});
  const [paymentErrors, setPaymentErrors] = useState({});

  // Add this state for form-level errors
  const [formErrors, setFormErrors] = useState({
    billing: null,
    shipping: null,
    payment: null
  });

  useEffect(() => {
    handlePayment();
  }, []);

  const handlePayment = async () => {
    try {
      const { data } = await axios.post(
        `${TENANT_URL}/api/nuvei/open-order`,
        formData
      );
      console.log('Open Order Response:', data);
      if (data && data.orderId && data.sessionToken) {
        setMerchantId(data?.merchantId);
        setSessionToken(data.sessionToken);
        setMerchantSiteId(data.merchantSiteId);
        setOrderId(data.orderId);
        initializeNuvei(data?.sessionToken, data?.merchantId, data?.merchantSiteId)
      } else {
        throw new Error(
          'Order ID or Session Token is missing from the response.'
        );
      }
    } catch (error) {
      console.error('Payment Error:', error);
    }
  };

  const initializeNuvei = (token, id, siteId) => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js";
    script.async = true;

    script.onload = () => {
      console.log("Nuvei SDK Loaded Successfully");
      // Ensure SafeCharge exists before proceeding
      if (window.SafeCharge) {
        console.log("SafeCharge Object Found:", window.SafeCharge);

        setIsSdkInitialized(true); // Set SDK initialized state to true
        setupNuveiPayment(token, id, siteId); // Proceed to set up Nuvei payment
      } else {
        console.error("SafeCharge object not found! Check the script URL.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load Nuvei SDK. Check network connection.");
    };

    document.body.appendChild(script);
  };

  const setupNuveiPayment = (token, id, siteId) => {
    try {
      if (!window.SafeCharge) {
        console.error('SafeCharge SDK is not loaded!');
        return;
      }

      if (!token) {
        console.error('Session Token is missing!');
        return;
      }
      console.log("Connection", id, siteId, token);
      const sc = window.SafeCharge({
        env: 'int', // Change to 'prod' for production
        merchantId: id,
        merchantSiteId: siteId,
        sessionToken: token,
      });
      window.sfc = sc;

      if (!sc || typeof sc.createPayment !== 'function') {
        console.error('SafeCharge object is not properly initialized:', sc);
        return;
      }
      console.log('SafeCharge Initialized:', sc);
      console.log('merchantSiteId Initialized:', merchantSiteId);

      try {
        const ScFields = sc.fields({
          fonts: [{
            cssUrl: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'
          },],
          locale: 'en'
        });

        const style = {
          base: {
            fontFamily: 'Roboto, sans-serif',
            color: "#045d47",
            fontSize: "14px",
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#00becf'
            }
          },
          invalid: {
            color: '#e5312b',
            ':focus': {
              color: '#303238'
            }
          },
          empty: {
            color: '#00becf',
            '::placeholder': {
              color: '#00becf'
            }
          },
          valid: {
            color: '#00A86b'
          }
        };
        if (window.card) return window.card;
        const scard = ScFields.create('card', {
          style: style,
        });
        setTimeout(() => {
          scard.attach(document.getElementById('card-field-placeholder'));
        }, 1000)
        window.card = scard;
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      console.error('Nuvei Payment Initialization Error:', error);
    }
  };

  const placeOrder = async () => {
    setPlaceOrderError('');
    setLoading(true);
    setGatewayError('');
    setFormErrors({ billing: null, shipping: null, payment: null });

    let hasErrors = false;

    // Validate billing address
    try {
      await addressValidationSchema.validate(formData.billing_address, { abortEarly: false });
    } catch (err) {
      const errors = err.inner.reduce((acc, error) => ({
        ...acc,
        [error.path]: error.message
      }), {});
      setBillingErrors(errors);
      setFormErrors(prev => ({ ...prev, billing: "Please fill all required billing information" }));
      hasErrors = true;
    }

    // Validate shipping address if deliver to same address is false
    if (!checked) {
      try {
        await addressValidationSchema.validate(formData.shipping_address, { abortEarly: false });
      } catch (err) {
        const errors = err.inner.reduce((acc, error) => ({
          ...acc,
          [error.path]: error.message
        }), {});
        setShippingErrors(errors);
        setFormErrors(prev => ({ ...prev, shipping: "Please fill all required shipping information" }));
        hasErrors = true;
      }
    }

    // Validate payment information
    if (!cardHolderName.trim()) {
      setPaymentErrors({ cardHolderName: 'Cardholder name is required' });
      setFormErrors(prev => ({ ...prev, payment: "Please enter all card details" }));
      hasErrors = true;
    }

    if (hasErrors) {
      setLoading(false);
      // Scroll to the first error
      const firstError = document.querySelector('.error-message');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    // Continue with existing order placement logic
    if (!sessionToken || !orderId) {
      console.error('Session token or Order ID is not available!');
      setLoading(false);
      return;
    }

    const payload = {
      ...formData,
          sessionToken: sessionToken, // Add sessionToken in request body
          orderId: orderId, // Add orderId in request body
          cart_id: formData.cart_id, // Add cart_id in request body
          preferred_delivery_method: deliveryMethod,
          deliver_to_same_address: checked,
          driver_instructions: driverInstructions,
          billing_address: formData.billing_address,
          shipping_address: formData.shipping_address
    }
    try {
      const response = await axios.post(
        `${TENANT_URL}/api/nuvei/update-order`,
        {
          ...formData,
          sessionToken: sessionToken, // Add sessionToken in request body
          orderId: orderId, // Add orderId in request body
          cart_id: formData.cart_id, // Add cart_id in request body
          preferred_delivery_method: deliveryMethod,
          deliver_to_same_address: checked,
          driver_instructions: driverInstructions,
          billing_address: formData.billing_address,
          shipping_address: formData.shipping_address
        }
      );
      if (response.data) {
        if(response.data.errCode === 1069){
          handlePayment();
          console.log(formData);
          return;
        }
        window?.sfc.createPayment({
          "sessionToken": sessionToken,
          "cardHolderName": cardHolderName,
          "merchantId": merchantId,
          "merchantSiteId": merchantSiteId,
          "paymentOption": window?.card,
          "billingAddress": formData.billing_address
        }, async function (res) {

          if (res.result != 'APPROVED') {
            console.warn("Payment Failed!");
            setGatewayError('Payment Failed! Please try again after checking details.')
            setLoading(false);
          }
          else {
            await axios.post(
              `${TENANT_URL}/api/nuvei/payment-status`,
              {
                sessionToken: sessionToken
              });
            setLoading(false);
            navigate('/success')
          };
        })
      }else{
        setLoading(false);
      }
    } catch (error) {
      console.error('Order Update Error:', error);
      setLoading(false);
    }
  };

  const updateCHName = (event) => {
    const name = event.target.value;
    setCardHolderName(name);
  }

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <ServiceNavbar />
      <CustomizationLandingPageLayout>
        <div className="checkout-page">
          {/* BreadCrumbs */}
          <Grid
            container
            sx={{
              padding: '1em 5em',
              backgroundColor: '#F0645B',
              '@media (max-width:1199px)': {
                padding: '1em 1em',
              },
            }}
          >
            <Grid item md={12}>
              <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <HouseIcon onClick={() => navigate('/')} />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/')}
                >
                  Home
                </Typography>
                <ArrowRightIcon />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                >
                  Checkout
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: '2em 5em',
              '@media (max-width:1199px)': {
                padding: '2em 1em',
              },
            }}
            marginBottom={7}
            spacing={2}
          > 

            <Grid container spacing={2}>
              {/* <Grid item md={8}>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      User name
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Enter address"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Unit Number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Address
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Region/State
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      City
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Zip Code
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Zip Code"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Email
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  onChange={handleCheckboxChange}
                  label="Deliver into same address"
                  sx={{
                    fontFamily: 'Inter !important',
                    marginBottom: '15px',
                  }}
                />

                {!checked && (
                  <Box>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={18}
                      color={'#000000'}
                      marginBottom={2}
                    >
                      Delivery Information
                    </Typography>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          User name
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          Address
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Enter address"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Unit Number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Address
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Region/State
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          City
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Zip Code
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Zip Code"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Email
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Phone Number
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={18}
                    color={'#000000'}
                    marginBottom={2}
                  >
                    Payment Option
                  </Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="credit"
                    name="radio-buttons-group"
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label={
                        <div>
                          <CreditIcon /> Debit/Credit Card
                        </div>
                      }
                    />
                  </RadioGroup>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Name on Card
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Card Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        Expire Date
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                        placeholder="DD/YY"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        CVC
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={18}
                  color={'#000000'}
                  marginBottom={2}
                >
                  Additional Information
                </Typography>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={14}
                  color={'#000000'}
                  marginBottom={1}
                >
                  delivery driver instructions{' '}
                  <span style={{ color: '#77878F' }}>(Optional)</span>
                </Typography>

                <TextareaAutosize
                  placeholder="Notes about your order, e.g. special notes for delivery"
                  style={{
                    width: '100%', // Custom width
                    padding: '10px', // Padding for better appearance
                    fontSize: '14px', // Custom font size
                    borderRadius: '8px', // Rounded corners
                    border: '1px solid #EBEBEC', // Light gray border
                  }}
                  minRows={5} // Minimum rows for the textarea
                  maxRows={6} // Maximum rows for the textarea
                />
              </Grid> */}


              <Grid item md={8}>
                {/* Add error messages at the top of the form */}
                {(formErrors.billing || formErrors.shipping || formErrors.payment) && (
                  <Box 
                    sx={{ 
                      backgroundColor: '#ffebee', 
                      padding: 2, 
                      marginBottom: 2, 
                      borderRadius: 1,
                      border: '1px solid #ef5350'
                    }}
                  >
                    <Typography color="error" variant="body2" gutterBottom>
                      Please correct the following errors:
                    </Typography>
                    {formErrors.billing && (
                      <Typography color="error" variant="body2">• {formErrors.billing}</Typography>
                    )}
                    {formErrors.shipping && (
                      <Typography color="error" variant="body2">• {formErrors.shipping}</Typography>
                    )}
                    {formErrors.payment && (
                      <Typography color="error" variant="body2">• {formErrors.payment}</Typography>
                    )}
                  </Box>
                )}

                <Formik
                  initialValues={{
                    firstName: formData.billing_address.firstName,
                    lastName: formData.billing_address.lastName,
                    email: formData.billing_address.email,
                    phone: formData.billing_address.phone,
                    unit: formData.billing_address.unit,
                    address: formData.billing_address.address,
                    address_type: formData.billing_address.address_type,
                    city: formData.billing_address.city,
                    state: formData.billing_address.state,
                    state_name: formData.billing_address.state_name,
                    country: formData.billing_address.country,
                    country_name: formData.billing_address.country_name,
                    zip: formData.billing_address.zip,
                  }}
                  validationSchema={addressValidationSchema}
                  onSubmit={() => {}}
                >
                  {({ setFieldValue, errors, touched }) => (
                    <Form>
                      <Box marginBottom={3}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={18}
                          color={'#000000'}
                        >
                          Billing Information
                        </Typography>
                      </Box>
  
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="firstName"
                            label="First Name"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.firstName && errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                              handleBillingChange('firstName')(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="lastName"
                            label="Last Name"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.lastName && errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                              handleBillingChange('lastName')(e);
                            }}
                          />
                        </Grid>
  
                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Field
                              name="address"
                              label="Address"
                              placeholder="Enter Address"
                              component={AutocompleteAddressInput}
                              form={{
                                setFieldValue: (field, value) => {
                                  if (field.includes('billing_address.')) {
                                    const actualField = field.split('.')[1];
                                    setFormData(prev => ({
                                      ...prev,
                                      billing_address: {
                                        ...prev.billing_address,
                                        [actualField]: value
                                      }
                                    }));
                                  } else {
                                    setFormData(prev => ({
                                      ...prev,
                                      billing_address: {
                                        ...prev.billing_address,
                                        [field]: value
                                      }
                                    }));
                                  }
                                  setFieldValue(field, value);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#000000'}
                              marginBottom={1}
                            >
                              Address Type
                            </Typography>
                            <Select
                              value={formData.billing_address.address_type}
                              onChange={(e) => {
                                setFormData(prev => ({
                                  ...prev,
                                  billing_address: {
                                    ...prev.billing_address,
                                    address_type: e.target.value
                                  }
                                }));
                              }}
                              displayEmpty
                              sx={selectStyle}
                            >
                              <MenuItem value="" disabled>
                                Select...
                              </MenuItem>
                              <MenuItem value="Home">Home</MenuItem>
                              <MenuItem value="Office">Office</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="unit"
                            label="Unit Number"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.unit && errors.unit}
                            helperText={touched.unit && errors.unit}
                            onChange={(e) => {
                              setFieldValue('unit', e.target.value);
                              handleBillingChange('unit')(e);
                            }}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Region/State"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.billing_address.state}
                            error={touched.state && errors.state}
                            helperText={touched.state && errors.state}
                            onChange={handleBillingChange('state')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="City"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.billing_address.city}
                            error={touched.city && errors.city}
                            helperText={touched.city && errors.city}
                            onChange={handleBillingChange('city')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Zip Code"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.billing_address.zip}
                            error={touched.zip && errors.zip}
                            helperText={touched.zip && errors.zip}
                            onChange={handleBillingChange('zip')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.billing_address.email}
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                            onChange={handleBillingChange('email')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.billing_address.phone}
                            error={touched.phone && errors.phone}
                            helperText={touched.phone && errors.phone}
                            onChange={handleBillingChange('phone')}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
  
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Deliver into same address"
                  sx={{
                    fontFamily: 'Inter !important',
                    marginY: 2
                  }}
                />
  
                {!checked && <Formik
                  initialValues={{
                    firstName: formData.shipping_address.firstName,
                    lastName: formData.shipping_address.lastName,
                    email: formData.shipping_address.email,
                    phone: formData.shipping_address.phone,
                    unit: formData.shipping_address.unit,
                    address: formData.shipping_address.address,
                    address_type: formData.shipping_address.address_type,
                    city: formData.shipping_address.city,
                    state: formData.shipping_address.state,
                    zip: formData.shipping_address.zip,
                  }}
                  validationSchema={addressValidationSchema}
                  onSubmit={() => {}}
                >
                  {({ setFieldValue, errors, touched }) => (
                    <Form>
                      <Box marginBottom={3}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={18}
                          color={'#000000'}
                        >
                          Shipping Information
                        </Typography>
                      </Box>
  
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="firstName"
                            label="First Name"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.firstName && errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                              handleShippingChange('firstName')(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="lastName"
                            label="Last Name"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.lastName && errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                              handleShippingChange('lastName')(e);
                            }}
                          />
                        </Grid>
  
                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Field
                              name="address"
                              label="Address"
                              placeholder="Enter Address"
                              component={AutocompleteAddressInput}
                              form={{
                                setFieldValue: (field, value) => {
                                  if (field.includes('shipping_address.')) {
                                    const actualField = field.split('.')[1];
                                    setFormData(prev => ({
                                      ...prev,
                                      shipping_address: {
                                        ...prev.shipping_address,
                                        [actualField]: value
                                      }
                                    }));
                                  } else {
                                    setFormData(prev => ({
                                      ...prev,
                                      shipping_address: {
                                        ...prev.shipping_address,
                                        [field]: value
                                      }
                                    }));
                                  }
                                  setFieldValue(field, value);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#000000'}
                              marginBottom={1}
                            >
                              Address Type
                            </Typography>
                            <Select
                              value={formData.shipping_address.address_type}
                              onChange={(e) => {
                                setFormData(prev => ({
                                  ...prev,
                                  shipping_address: {
                                    ...prev.shipping_address,
                                    address_type: e.target.value
                                  }
                                }));
                              }}
                              displayEmpty
                              sx={selectStyle}
                            >
                              <MenuItem value="" disabled>
                                Select...
                              </MenuItem>
                              <MenuItem value="home">Home</MenuItem>
                              <MenuItem value="office">Office</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="unit"
                            label="Unit Number"
                            component={TextField}
                            fullWidth
                            variant="outlined"
                            sx={inpuStyle}
                            error={touched.unit && errors.unit}
                            helperText={touched.unit && errors.unit}
                            onChange={(e) => {
                              setFieldValue('unit', e.target.value);
                              handleShippingChange('unit')(e);
                            }}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Region/State"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.shipping_address.state}
                            error={touched.state && errors.state}
                            helperText={touched.state && errors.state}
                            onChange={handleShippingChange('state')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="City"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.shipping_address.city}
                            error={touched.city && errors.city}
                            helperText={touched.city && errors.city}
                            onChange={handleShippingChange('city')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Zip Code"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.shipping_address.zip}
                            error={touched.zip && errors.zip}
                            helperText={touched.zip && errors.zip}
                            onChange={handleShippingChange('zip')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.shipping_address.email}
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                            onChange={handleShippingChange('email')}
                          />
                        </Grid>
  
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            sx={inpuStyle}
                            value={formData.shipping_address.phone}
                            error={touched.phone && errors.phone}
                            helperText={touched.phone && errors.phone}
                            onChange={handleShippingChange('phone')}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>}
  
                <Box className="cart_left" marginBottom={2} marginTop={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={18}
                    color={'#000000'}
                    marginBottom={2}
                  >
                    Payment Information
                  </Typography>
                  {isSdkInitialized ? (
                    <form action="/charge" method="post" id="payment-form">
                      <div className="item">
                        <label htmlFor="cardHolderName">Cardholder name</label>
                        <input 
                          type="text" 
                          style={{
                            marginTop: '2px',
                            padding: '10px',
                            fontSize: '14px',
                            borderRadius: '10px',
                            border: '2px solid #EBEBEC',
                          }}
                          placeholder="Cardholder name" 
                          id="cardHolderName" 
                          name="cardHolderName" 
                          onChange={updateCHName}
                          className={paymentErrors.cardHolderName ? 'error' : ''}
                        />
                        {paymentErrors.cardHolderName && (
                          <div className="error-message">{paymentErrors.cardHolderName}</div>
                        )}
                      </div>
                      <div className="item100" id="card_number">
                        <label htmlFor="card-field-placeholder">
                          Credit or debit card
                        </label>
                        <div 
                         style={{
                          marginTop: '2px',
                          padding: '10px',
                          fontSize: '14px',
                          borderRadius: '10px',
                          border: '2px solid #EBEBEC',
                        }}
                        id="card-field-placeholder" className="some initial css-classes">
                        </div>
                        <div id="scard-errors" role="alert"></div>
                        {gatewayError && <div id="scard-errors" role="alert" style={{color: 'red',fontSize: '12px'}}>{gatewayError}</div>}
                      </div>
                    </form>
                  ) : (
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ mb: 2 }}>
                        <Skeleton 
                          variant="text" 
                          width={120} 
                          height={20} 
                          sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                          variant="rectangular" 
                          width="100%" 
                          height={40} 
                          sx={{ borderRadius: 1 }} 
                        />
                      </Box>
                      <Box>
                        <Skeleton 
                          variant="text" 
                          width={150} 
                          height={20} 
                          sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                          variant="rectangular" 
                          width="100%" 
                          height={40} 
                          sx={{ borderRadius: 1 }} 
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
  
                <Box marginTop={3}>
                  <Typography variant="h4" fontFamily={'Inter !important'} fontSize={18} color={'#000000'} marginBottom={2}>
                    Additional Information
                  </Typography>
                  <TextareaAutosize
                    placeholder="Notes about your order, e.g. special notes for delivery"
                    style={{
                      width: '100%',
                      padding: '10px',
                      fontSize: '14px',
                      borderRadius: '8px',
                      border: '1px solid #EBEBEC',
                    }}
                    minRows={5}
                    maxRows={6}
                    value={driverInstructions}
                    onChange={(e) => setDriverInstructions(e.target.value)}
                  />
                </Box>
              </Grid>



              <Grid item md={4} xs={12}>
                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={17}
                    color={'#000000'}
                    marginBottom={3}
                  >
                    Order Summary
                  </Typography>

                  {cartDetails.cart ? (
                    <>
                      {cartDetails.cart.items.map((element, index) => (
                        element.menu_plan && (
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap="6px"
                            marginBottom={3}
                        >
                          <img
                            src={element.menu_plan.image_url}
                            alt={element.menu_plan.name}
                            className="tImg"
                          />
                          <Box>
                            <Typography
                              variant="h1"
                              fontSize={15}
                              color={'#000000'}
                              fontWeight={'bold'}
                              marginBottom={'2px'}
                            >
                              {element.menu_plan.name}
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              {element.menu_plan.slug}
                            </Typography>

                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              Quantity : {element.quantity}
                            </Typography>
                          </Box>
                        </Box>
                      )))}
                      {cartDetails.cart.items.map((element, index) => (
                        element.trial_item && (
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap="6px"
                          marginBottom={3}
                        >
                          <img
                            src={element.trial_item.image_url}
                            alt={element.trial_item.name}
                            className="tImg"
                          />
                          <Box>
                            <Typography
                              variant="h1"
                              fontSize={15}
                              color={'#000000'}
                              fontWeight={'bold'}
                              marginBottom={'2px'}
                            >
                              {element.trial_item.name}
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              Trial Meal
                            </Typography>

                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              Quantity : 1
                            </Typography>
                          </Box>
                        </Box>
                      )))}

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Sub-total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_price}
                        </Typography>
                        
                      </Box>

                      {!!additinaolItems ? (
                        <>
                          <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            marginBottom={3}
                          >
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#5F6C72'}
                            >
                              Additional Items
                            </Typography>
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#000000'}
                            >
                              ${cartDetails.selected_additional_items_total_price}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}

                      {cartTaxesDetails.map((element, index) => (
                        <Box
                          key={index}
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={3}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            {element.name}
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            {element.type == 'fixed' ? (
                              <>
                                {element.value == '0' ? (
                                  'Free'
                                ) : (
                                  <>${element.value} </>
                                )}
                              </>
                            ) : (
                              <>{element.value}%</>
                            )}
                            {/* {element.type == 'percentage' ? '' : '$'}
                            {element.value}
                            {element.type == 'percentage' ? '%' : ''} */}
                          </Typography>
                        </Box>
                      ))}

                      {cartDetails.total_discount > 0 && (
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={2}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            Discount
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            ${cartDetails.total_discount}
                          </Typography>
                        </Box>
                      )}
                      

                      {/* <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Tax
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_tax}
                        </Typography>
                      </Box> */}

                      <Divider
                        sx={{
                          backgroundColor: '#E4E7E9',
                          marginBottom: '15px',
                        }}
                      />

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                          fontWeight={'bold'}
                        >
                          {/* ${(parseFloat(cartDetails.final_amount) + parseFloat(additinaolItemsTotals))} */}
                            $ {parseFloat(cartDetails.final_amount).toFixed(2)}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          width: '100%',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                        onClick={placeOrder}
                      >
                        Place order
                      </Button>
                      <div className="p-4 bg-white rounded-lg shadow-sm">
                        <p className="text-sm text-gray-600 mb-3">
                          Secure payment via:
                        </p>
                        <div className="d-flex items-center gap-2 flex-wrap">
                          {paymentMethods.map((method) => (
                            <div className="rounded border border-gray-200 hover:border-gray-300 p-2">
                              {method.icon}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomizationLandingPageLayout>
      <ServiceFooter />
    </>
  );
};

export default Cart;