import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Typography,
  FormLabel,
  Checkbox,
  useTheme,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import WalletsComponent from './components/WalletsComponent';
import SubscriptionComponent from './components/SubscriptionComponent';
import NewPlanComponent from './components/NewPlanComponent';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import AutocompleteAddressInput from '../../../tenant/pages/cart/components/autocompleteAddressInput/autocompleteAddressInput';
import * as Yup from 'yup';


const inputStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#EBEBEC',
    fontFamily: 'Inter !important',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: '1em',
  },
};

const selectStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiSelect-select': {
    padding: '13px 14px',
    fontSize: '14px',
  },
};

const OrderCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { newCheckoutData, oldCheckoutData } = location.state;

  // if(!newCheckoutData?.mealFor) {
  //   navigate("/customer/subscription");
  // }

  const [taxes, setTaxes] = useState([]);
  const [totalCalculatedAmount, setcalculatedAmount] = useState(0);

  // const [checked, setChecked] = useState(true);

  // const handleCheckboxChange = (event) => {
  //   setChecked(event.target.checked);
  // }; 


  const [isSdkInitialized, setIsSdkInitialized] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('')

  const getPlanTaxes = async () => {
    try {
      console.log("Asdasd2");
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/plan/taxes?preferred_delivery_method=${oldCheckoutData?.mealFor}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      console.log("mealConfig:",mealConfig);

      const response = await axios({ ...mealConfig });
      console.log("response:",response);
      setTaxes(response.data.taxes);
      recalculateValues();
    } catch (error) {}
  };

  const recalculateValues = () => {
    let total = (oldCheckoutData?.newPlan?.plan_price || 0) + (oldCheckoutData?.newAdditional?.total_price || 0);
  
    let totalAmountCal = parseFloat(total);
    let discountAmountCal = parseFloat(oldCheckoutData?.discountCoupan?.discountAmount);
    let accountBalanceCal = parseFloat(oldCheckoutData?.subscription?.account_balance);
  
    let finalAmount = totalAmountCal - discountAmountCal;
    finalAmount = finalAmount.toFixed(2);
  
    let taxAmount = 0;
    taxes.map((elements) => {
      if (elements.type == 'percentage') {
        taxAmount =
          taxAmount +
          (parseFloat(finalAmount) * parseFloat(elements.value)) / 100;
      }
      if (elements.type == 'fixed') {
        taxAmount = taxAmount + elements.value;
      }
      console.warn(elements.value, '---taxAmount---:', taxAmount);
    });
  
    let totalAmount =
      parseFloat(totalAmountCal) -
      parseFloat(discountAmountCal) +
      parseFloat(taxAmount) -
      parseFloat(accountBalanceCal);
    setcalculatedAmount(totalAmount.toFixed(2));
    return totalAmount.toFixed(2);
  };
  

  useEffect(() => {  
    recalculateValues();
  }, [taxes])

  useEffect(() => {
    getPlanTaxes();
  }, [oldCheckoutData?.mealFor])


  const [sessionToken, setSessionToken] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [merchantSiteId, setMerchantSiteId] = useState('');
  const [gatewayError, setGatewayError] = useState('');
  const [placeOrderError, setPlaceOrderError] = useState('');

  // Add validation state
  const [paymentErrors, setPaymentErrors] = useState({});

  // Add this state for form-level errors
  const [formErrors, setFormErrors] = useState({
    payment: null
  });


  useEffect(() => {
    handlePayment();
  }, []);

  const handlePayment = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;

    try {
      const { data } = await axios.post(
        `${TENANT_URL}/customer/app/nuvei/open-order`,
        newCheckoutData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log('Open Order Response:', data);
      if (data && data.orderId && data.sessionToken) {
        setMerchantId(data?.merchantId);
        setSessionToken(data.sessionToken);
        setMerchantSiteId(data.merchantSiteId);
        setOrderId(data.orderId);
        initializeNuvei(data?.sessionToken, data?.merchantId, data?.merchantSiteId)
      } else {
        throw new Error(
          'Order ID or Session Token is missing from the response.'
        );
      }
    } catch (error) {
      console.error('Payment Error:', error);
    }
  };

  const initializeNuvei = (token, id, siteId) => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js";
    script.async = true;
    script.onload = () => {
      console.log("Nuvei SDK Loaded Successfully");
      // Ensure SafeCharge exists before proceeding
      if (window.SafeCharge) {
        console.log("SafeCharge Object Found:", window.SafeCharge);
        setIsSdkInitialized(true); // Set SDK initialized state to true
        setupNuveiPayment(token, id, siteId); // Proceed to set up Nuvei payment
      } else {
        console.error("SafeCharge object not found! Check the script URL.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load Nuvei SDK. Check network connection.");
    };

    document.body.appendChild(script);
  };

  const setupNuveiPayment = (token, id, siteId) => {
    try {
      if (!window.SafeCharge) {
        console.error('SafeCharge SDK is not loaded!');
        return;
      }

      if (!token) {
        console.error('Session Token is missing!');
        return;
      }
      console.log("Connection", id, siteId, token);
      const sc = window.SafeCharge({
        env: 'int', // Change to 'prod' for production
        merchantId: id,
        merchantSiteId: siteId,
        sessionToken: token,
      });
      window.sfc = sc;

      if (!sc || typeof sc.createPayment !== 'function') {
        console.error('SafeCharge object is not properly initialized:', sc);
        return;
      }
      console.log('SafeCharge Initialized:', sc);
      console.log('merchantSiteId Initialized:', merchantSiteId);

      try {
        const ScFields = sc.fields({
          fonts: [{
            cssUrl: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'
          },],
          locale: 'en'
        });

        const style = {
          base: {
            fontFamily: 'Roboto, sans-serif',
            color: "#045d47",
            fontSize: "14px",
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#00becf'
            }
          },
          invalid: {
            color: '#e5312b',
            ':focus': {
              color: '#303238'
            }
          },
          empty: {
            color: '#00becf',
            '::placeholder': {
              color: '#00becf'
            }
          },
          valid: {
            color: '#00A86b'
          }
        };
        if (window.card) return window.card;
        const scard = ScFields.create('card', {
          style: style,
        });
        setTimeout(() => {
          scard.attach(document.getElementById('card-field-placeholder'));
        }, 1000)
        window.card = scard;
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      console.error('Nuvei Payment Initialization Error:', error);
    }
  };


  const placeOrder = async () => {
    setPlaceOrderError('');
    setLoading(true);
    setGatewayError('');
    setFormErrors({payment: null });

    let hasErrors = false;


    // Validate payment information
    if (!cardHolderName.trim()) {
      setPaymentErrors({ cardHolderName: 'Cardholder name is required' });
      setFormErrors(prev => ({ ...prev, payment: "Please enter all card details" }));
      hasErrors = true;
    }

    if (hasErrors) {
      setLoading(false);
      // Scroll to the first error
      const firstError = document.querySelector('.error-message');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    // Continue with existing order placement logic
    if (!sessionToken || !orderId) {
      console.error('Session token or Order ID is not available!');
      setLoading(false);
      return;
    }

    try {
      const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
      const response = await axios.post(
        `${TENANT_URL}/customer/app/nuvei/update-order`,
        {
          ...newCheckoutData,
          sessionToken: sessionToken, // Add sessionToken in request body
          orderId: orderId, // Add orderId in request body
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data) {
        if(response.data.errCode === 1069){
          handlePayment();
          console.log(newCheckoutData);
          return;
        }
        window?.sfc.createPayment({
          "sessionToken": sessionToken,
          "cardHolderName": cardHolderName,
          "merchantId": merchantId,
          "merchantSiteId": merchantSiteId,
          "paymentOption": window?.card,
          "billingAddress": newCheckoutData.billing_address
        }, async function (res) {

          if (res.result != 'APPROVED') {
            console.warn("Payment Failed!");
            setGatewayError('Payment Failed! Please try again after checking details.')
            setLoading(false);
          }
          else {
            await axios.post(
              `${TENANT_URL}/api/nuvei/payment-status`,
              {
                sessionToken: sessionToken
              });
            setLoading(false);
            navigate('/customer/profile')
          };
        })
      }else{
        setLoading(false);
      }
    } catch (error) {
      console.error('Order Update Error:', error);
      setLoading(false);
    }
  };

  const updateCHName = (event) => {
    const name = event.target.value;
    setCardHolderName(name);
  }
  
  const SPGridHeaderSubmit = ({ children, onClick, sx }) => {
    const theme = useTheme();

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',
          marginLeft: '10px',
          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
          ...sx,
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  const SPGridHeaderButton = ({ children, onClick, sx }) => {
    const theme = useTheme();
    return (
      <div style={{marginRight: '10px'}}>
        <Button
        onClick={onClick}
        variant="outlined"
        sx={{
          marginRight: '10px',
          border: '1px solid #F0645B',
          color: '#F0645B',
        }}
      >
        {children}
      </Button>
      </div>
      
    );
  };
  return (
    <div className="checkout-page">
      <Grid
        container
        sx={{
          padding: '1em 2em',
          '@media (max-width:1199px)': {
            padding: '1em 1em',
          },
        }}
        spacing={2}
      >
        <Grid item md={6}>
          {/* Payment Section */}
         

          <Box className="cart_left" marginBottom={2} marginTop={2} padding={2}>
            <Typography
              variant="h4"
              fontFamily={'Inter !important'}
              fontSize={18}
              color={'#000000'}
              marginBottom={2}
            >
              Payment Information
            </Typography>
            {isSdkInitialized ? (
              <form action="/charge" method="post" id="payment-form">
                <div className="item">
                  <label htmlFor="cardHolderName">Cardholder name</label>
                  <input 
                    type="text" 
                    style={{
                      marginTop: '2px',
                      padding: '10px',
                      fontSize: '14px',
                      borderRadius: '10px',
                      border: '2px solid #EBEBEC',
                    }}
                    placeholder="Cardholder name" 
                    id="cardHolderName" 
                    name="cardHolderName" 
                    onChange={updateCHName}
                    className={paymentErrors.cardHolderName ? 'error' : ''}
                  />
                  {paymentErrors.cardHolderName && (
                    <div className="error-message">{paymentErrors.cardHolderName}</div>
                  )}
                </div>
                <div className="item100" id="card_number">
                  <label htmlFor="card-field-placeholder">
                    Credit or debit card
                  </label>
                  <div 
                    style={{
                    marginTop: '2px',
                    padding: '10px',
                    fontSize: '14px',
                    borderRadius: '10px',
                    border: '2px solid #EBEBEC',
                  }}
                  id="card-field-placeholder" className="some initial css-classes">
                  </div>
                  <div id="scard-errors" role="alert"></div>
                  {gatewayError && <div id="scard-errors" role="alert" style={{color: 'red',fontSize: '12px'}}>{gatewayError}</div>}
                </div>
              </form>
            ) : (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ mb: 2 }}>
                  <Skeleton 
                    variant="text" 
                    width={120} 
                    height={20} 
                    sx={{ mb: 1 }} 
                  />
                  <Skeleton 
                    variant="rectangular" 
                    width="100%" 
                    height={40} 
                    sx={{ borderRadius: 1 }} 
                  />
                </Box>
                <Box>
                  <Skeleton 
                    variant="text" 
                    width={150} 
                    height={20} 
                    sx={{ mb: 1 }} 
                  />
                  <Skeleton 
                    variant="rectangular" 
                    width="100%" 
                    height={40} 
                    sx={{ borderRadius: 1 }} 
                  />
                </Box>
              </Box>
            )}
          </Box>
          
          {/* <FormControlLabel
            control={<Checkbox defaultChecked />}
            onChange={handleCheckboxChange}
            label="Deliver into same address"
            sx={{
              fontFamily: 'Inter !important',
              marginBottom: '15px',
            }}
          />

          {!checked && (
            <Box>
              <Typography
                variant="h4"
                fontFamily={'Inter !important'}
                fontSize={18}
                color={'#000000'}
                marginBottom={2}
              >
                Delivery Information
              </Typography>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    First Name *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter first name"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Last Name *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter last name"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter Email"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Enter phone number"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Address *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Street address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={'7px'}
                  >
                    Unit *
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Apt, Suite, Unit"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Country
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Region/State
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    City
                  </Typography>
                  <Select sx={selectStyle} displayEmpty>
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value={10}>abc</MenuItem>
                    <MenuItem value={20}>abc 1</MenuItem>
                    <MenuItem value={30}>abc 1</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={14}
                    color={'#000000'}
                    marginBottom={1}
                  >
                    Zip Code
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={inputStyle}
                    placeholder="Zip Code"
                  />
                </Grid>
              </Grid>
            </Box>
          )} */}
        </Grid>

        {/* Order Summary */}
        <Grid item md={6}>
          <WalletsComponent walletAmount={(oldCheckoutData?.subscription?.account_balance || 0).toFixed(2)}/>
          <Grid container spacing={2} className="my-1">
            <Grid item md={12} lg={6} xl={6}>
              <SubscriptionComponent 
                planDetail={oldCheckoutData?.subscription?.plan_details?.item_names}
                planFree={oldCheckoutData?.subscription?.plan_details?.plan_price}
                addon={oldCheckoutData?.subscription?.plan_details?.addon_names}
                additionalDetail={oldCheckoutData?.subscription?.additional_plan_details?.items}
                additionalFee={oldCheckoutData?.subscription?.additional_plan_details?.total_price}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6}>
              <NewPlanComponent 
                planDetail={oldCheckoutData?.newPlan?.food_items}
                planFree={oldCheckoutData?.newPlan?.plan_price}
                addon={oldCheckoutData?.newPlan?.addons}
                additionalDetail={oldCheckoutData?.newAdditional?.items}
                additionalFee={oldCheckoutData?.newAdditional?.total_price}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  New Plan
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${(oldCheckoutData?.newPlan?.plan_price || 0).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Additional Items
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${(oldCheckoutData?.newAdditional?.total_price || 0).toFixed(2)}
                </Typography>
              </Box>
              {(oldCheckoutData?.discountCoupan?.discountAmount > 0 &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Coupon
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  {/* -${oldCheckoutData?.discountCoupan?.discountAmount || 0} */}
                  {
                   oldCheckoutData?.discountCoupan?.discountAmount > 0
                   ? `-${oldCheckoutData.discountCoupan.discountAmount}`
                   : oldCheckoutData?.discountCoupan
                   ? `${oldCheckoutData.discountCoupan.discountAmount}`
                   : "0"
                  }
                </Typography>
              </Box>
              )}

              {taxes.map((tax, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '3px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#3F2F5B',
                      opacity: 0.6,
                    }}
                  >
                    {tax.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    {tax.type == 'percentage' ? (
                      tax.value + '%'
                    ) : (
                      <>{tax.value == '0' ? 'Free' : '$' + tax.value}</>
                    )}
                  </Typography>
                </Box>
              ))}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Wallet Amount
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  -${(oldCheckoutData?.subscription?.account_balance || 0).toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '3px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#3F2F5B',
                    opacity: 0.6,
                  }}
                >
                  Total Amount Payment
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000000',
                  }}
                >
                  ${totalCalculatedAmount<=0 ? 0 : (totalCalculatedAmount || 0) }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
          }}
        >
          <SPGridHeaderButton variant="filled" text={'Back'} onClick={()=> navigate("/customer/subscription")}>
            Back
          </SPGridHeaderButton>
          <SPGridHeaderSubmit variant="filled" text={'Update'} onClick={placeOrder}>
            Submit
          </SPGridHeaderSubmit>
          
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderCheckout;
