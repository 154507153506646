import { FormControl, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';

const RouteSeacrhBy = ({ isMdUp, route, setRoute, list,width='100%', label }) => {
  const handleChange = (event) => {
    setRoute(event.target.value);
  };



  return (
    <FormControl sx={{ width: '100%' }}>
      <TextField
        select
        labelId="search-by-label"
        id="search-by-select"
        value={route}
        label={label}
        onChange={handleChange}
        size="small"
        InputProps={{
          sx: {
            width: width,
            minWidth: '160px',
            height: '40px',
            background: 'transparent',
            borderRadius: '30px',
            fontSize: '14px',
            '& fieldset': {
              borderRadius: '30px',
            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid #3F2F5B',
                borderColor: '#3F2F5B !important',
              },
            },
            '&.Mui-focused': {
              '& fieldset': {
                borderColor: '#FF7676 !important',
              },
            },
            '@media (max-width: 873px)': {
              width: '100%',
            },
          },
        }}
      >
        {list.map((item) => (
          <MenuItem
            key={item.id}
            value={item.route_name}
            sx={{ textAlign: 'center', fontSize: '14px' }}
          >
            {item.route_name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default RouteSeacrhBy;
