import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  Grid,
  Typography,
  Modal,
  Chip,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Spinner from '../../../components/utils/spinner';
import ActiveCalIcon from '../../../icons/activecal.svg';
import InActiveCalIcon from '../../../icons/inactivecal.svg';
import CrossIcon from '../../../icons/cross.svg';
import Cross from '../../../tenant/pages/profile/assets/cross.svg';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';

const MealPlus = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { show } = useSnackbar();
  const [secondSelected, setSecondSelected] = useState('1');
  const [selectedMealPlusData, setSelectedMealPlusData] = useState(0);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());

  const [currentMonthName, setCurrentMonthName] = useState(new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase());
  const [restaurantTiming, setRestaurantTiming] = useState({});
  const [mealPlusAddons, setMealPlusAddons] = useState([]);

  const [allMealItems, setAllMealItems] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState({});

  const [mealPlanName, setMealPlanName] = useState();

  const [menuPlansListing, setMenuPlanListing] = useState([]);
  const [menuPlanMealListing, setMenuPlanMealListing] = useState([]);
  const [mealPlanId, setMealPlanID] = useState(menuPlansListing[0]?.id || '');
  const [weeklyMealPlusData, setWeeklyMealPlusData] = useState([]);
  const [selectedWeekNo, setSelectedWeekNo] = useState(0);

  console.log("menuPlanMealListing:",menuPlanMealListing);

  const handleSelectChange = (date, value) => {
    if (!value) return;
    setSelectedMeals((prev) => {
      const updated = { ...prev };
      if (!updated[date]) {
        updated[date] = [];
      }
      
      // Handle different item structures
      const itemId = value.id || value.item_id;
      if (itemId && !updated[date].includes(itemId)) {
        updated[date].push(itemId);
      }
      
      return updated;
    });
    
    // Update the weeklyMealPlusData to reflect the change
    setWeeklyMealPlusData((prev) => {
      return prev.map(dayData => {
        if (dayData.date === date) {
          // Create a new item object with the correct structure
          const newItem = {
            item_id: value.id || value.item_id,
            item_name: value.name || value.item_name
          };
          
          // Add the item if it doesn't already exist
          const itemExists = dayData.items.some(item => item.item_id === newItem.item_id);
          if (!itemExists) {
            return {
              ...dayData,
              items: [...dayData.items, newItem]
            };
          }
        }
        return dayData;
      });
    });
  };

  const handleRemoveMeal = (date, mealId) => {
    // Update selectedMeals state
    setSelectedMeals((prev) => {
      const updated = { ...prev };
      if (updated[date]) {
        updated[date] = updated[date].filter((id) => id !== mealId);
        if (updated[date].length === 0) {
          delete updated[date];
        }
      }
      return updated;
    });
    
    // Update weeklyMealPlusData to reflect the removal
    setWeeklyMealPlusData((prev) => {
      return prev.map(dayData => {
        if (dayData.date === date) {
          return {
            ...dayData,
            items: dayData.items.filter(item => item.item_id !== mealId)
          };
        }
        return dayData;
      });
    });
  };

  const weekStyle = {
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '15px 10px',
    borderRadius: '15px',
    boxShadow: '0px 4px 4px 0px #00000026',
    marginBottom: '15px',
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
    height: '119px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #F5F6F8',
    transition: 'all ease 0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  };
  const weekNewStyle = {
    borderRadius: '10px',
    paddingY: '10px',
    height: 'auto',
    flexGrow: 1,
    maxWidth: '100%',
    fontWeight: '400',
  };
  const weekStyleActive = {
    backgroundColor: '#F0645B',
    padding: '15px 10px',
    boxShadow: 'none',
    borderColor: '#F0645B',
  };

  const saveMealPlusData = async () => {
    const formattedData = Object.entries(selectedMeals).reduce(
      (acc, [date, meals]) => {
        const [year, month, day] = date.split('-');
        const formattedDate = `${day}-${month}-${year}`;
        acc[formattedDate] = meals;
        return acc;
      },
      {}
    );

    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const addConfig = {
      method: 'put',
      url: `${TENANT_URL}/meal-plus/${mealPlanId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    const data = {
      items: formattedData,
    };

    try {
      const response = await axios({ ...addConfig, data });
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(
        'An error occurred while logging in. Please check your credentials or try again later.'
      );
    }
  };

  const getAllMealItems = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/all/food/item?is_active=true`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      setAllMealItems(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const weekNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const generateMonthWeeks = (month, year, checkDate = true) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const weeks = [];
    let currentWeek = [];

    // Add leading placeholders to align the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      currentWeek.push({ date: null, isPlaceholder: true });
    }

    // Fill the weeks with actual days from the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      currentWeek.push({
        date: currentDate,
        isPlaceholder: false,
        plan_name: '',
        meal_name: [],
        addon_name: [],
        is_available: false,
      });

      // When a week is complete, push it to weeks and start a new one
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }

    // Add trailing placeholders to align the last week
    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push({ date: null, isPlaceholder: true });
    }
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    // Get the current date to compare if necessary
    const currentDate = new Date();

    // Helper to format the date in local timezone
    const formatLocalDate = (date) => {
      if (!date) return null;
      const offsetDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return offsetDate.toISOString().split('T')[0];
    };

    // Map the weeks to include metadata
    return weeks.map((week) => {
      const weekStartDate = week.find((day) => day.date)?.date || null;
      const weekEndDate =
        [...week].reverse().find((day) => day.date)?.date || null;

      const isDateInRange =
        checkDate && weekStartDate && weekEndDate
          ? currentDate >= weekStartDate && currentDate <= weekEndDate
          : false;

      return {
        startDate: formatLocalDate(weekStartDate),
        endDate: formatLocalDate(weekEndDate),
        days: week,
        isDateInRange,
      };
    });
  };

  const weeklyData = generateMonthWeeks(currentMonthIndex, currentYear);

  const handleMealPlus = async (startDate, endDate, weekNo) => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/meal-plus/${mealPlanId}?week_number=${weekNo}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      
          //  Set the weekly meal plus data directly from the response
      setWeeklyMealPlusData(response.data.items || []);
      // setWeeklyMealPlusData(sample || []);
      debugger
      setSelectedWeekNo(weekNo);

      // Clear previous selections before adding new ones
      setSelectedMeals({});

      // Process the response data to update selectedMeals
      if (response.data.items && Array.isArray(response.data.items)) {
        response.data.items.forEach((dayData) => {
          if (!dayData.day_off && dayData.items && dayData.items.length > 0) {
            const dateKey = dayData.date;
            // Add each item to selectedMeals
            dayData.items.forEach((item) => {
              handleSelectChange(dateKey, item);
            });
          }
        });
      }
    } catch (error) {
      console.error('Error fetching meal plus data:', error);
      show('Failed to fetch meal plan data. Please try again.');
    }
  };
  const getMenuPlusData = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/menu/plan/calender?month=12&year=2024`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);

      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };
  const calenderAvailabilities = async () => {
    const currentMonthName = new Date()
      .toLocaleString('en-US', { month: 'long' })
      .toLowerCase();
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/meal-plus/calendar-availabilities?month=${currentMonthName}&menu_plan_id=1`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const getRestaurantTiming = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/restaurant-timing`,
      };
      const response = await axios(config);
      setRestaurantTiming(response.data.data[0]);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const getMenuPlans = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/menu-plans`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      if (response.data.data.length > 0) {
        setMealPlanID(response.data.data[0].id);
        setMealPlanName(response.data.data[0].name);
      }
      setMenuPlanListing(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day} ${months[parseInt(month, 10) - 1]} ${year}`;
  }

  useEffect(() => {
    getRestaurantTiming();
    getMenuPlans();
    getAllMealItems();
  }, []);

  useEffect(() => {
    // Clear data and UI state when plan changes
    setSelectedMeals({});
    setMealPlusAddons([]);
    setSelectedMealPlusData(0); // This will hide the right sidebar and remove active state
  }, [mealPlanId]);

  const getMEnuPlanMonthlyData = async () => {
    const currentMonthName = new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase();
    
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/menu-plans/${mealPlanId}?month=${currentMonthName}&year=${currentYear}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      console.log("response:response:",response.data)
      rearrangeWeeklyData(response.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const rearrangeWeeklyData = async (menuPlanMealListingData) => {
    const updatedWeeklyData = await mergeWeeklyAndMonthlyData(
      menuPlanMealListingData,
      weeklyData
    );
    setMenuPlanMealListing(updatedWeeklyData);
  };
  const mergeWeeklyAndMonthlyData = (menuPlanMealListing, weeklyData) => {
    const { monthly_plans, available_dates, available_weeks } = menuPlanMealListing;
    console.log("menuPlanMealListing:",menuPlanMealListing);

    // Ensure available_dates is an array
    const availableDates = Array.isArray(available_dates)
      ? available_dates
      : [];
    const availableDatesSet = new Set(
      availableDates.map((date) => date.split('T')[0])
    );

    // Ensure monthly_plans is an array
    const monthlyPlans = Array.isArray(monthly_plans) ? monthly_plans : [];
    const monthlyPlansMap = new Map();
    monthlyPlans.forEach((plan) => {
      const planDate = plan.plan_date?.split('T')[0];
      if (planDate) {
        if (!monthlyPlansMap.has(planDate)) {
          monthlyPlansMap.set(planDate, []);
        }
        monthlyPlansMap.get(planDate).push(plan);
      }
    });

    // Default to empty array if available_weeks is missing or invalid
    const validAvailableWeeks = Array.isArray(available_weeks)
      ? available_weeks
      : [];

    // Iterate through weekly data and update each day
    let newWeeklyData = [];

    console.log("weeklyData:",weeklyData);

    weeklyData.forEach((week, index) => {
      let singleWeekData = [];

      week.days.forEach((day) => {
        let singleData = {
          addon_name: '',
          date: '',
          isPlaceholder: false,
          is_available: false,
          meal_name: '',
          plan_name: '',
          isOpen: true,
          dayName: "",
        };

        if (!!day.date) {
          var dateObj = new Date(day.date);
          const dayDate = dateObj.toLocaleDateString('en-US'); // Adjust locale as needed
          singleData.date = dayDate;
          singleData.day = String(dateObj.getDate()).padStart(2, '0');

          dateObj = new Date(dayDate);

          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, '0');
          const daynumber = String(dateObj.getDate()).padStart(2, '0');

          const formattedDate = `${year}-${month}-${daynumber}`;
          singleData.date = formattedDate;

          if (monthlyPlansMap.has(formattedDate)) {
            const plans = monthlyPlansMap.get(formattedDate);
            singleData.plan_name = plans
              .map((plan) => plan.meal_name)
              .join(', ');
            singleData.meal_name = plans
              .flatMap((plan) => plan.food_meal_name)
              .join(', ');
            singleData.addon_name = plans
              .flatMap((plan) => plan.addons_name)
              .join(', ');
          }

          const date = new Date(dayDate);
          const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
          const isOpen = restaurantTiming[dayName.toLowerCase()] || false;
          singleData.isOpen = isOpen;
          singleData.dayName = dayName;
          

          dateObj.setDate(dateObj.getDate() + 1); // Subtract one day
          const tomorrowDayDate = dateObj.toISOString().split('T')[0];
          if (availableDatesSet.has(tomorrowDayDate)) {
            singleData.is_available = true;
          }
        } else {
          singleData.date = '';
        }

        singleWeekData.push(singleData);
      });

      // Determine the week key (assuming `index + 1` represents the week number)
      const weekKey = `week_${index + 1}`;
      const isStar = validAvailableWeeks.includes(weekKey);

      

      newWeeklyData.push({
        days: singleWeekData,
        endDate: week.endDate,
        isDateInRange: week.isDateInRange,
        startDate: week.startDate,
        isStar: isStar, // Set isStar based on available_weeks
      });
    });
    return newWeeklyData;
  };

  useEffect(() => {
    getMEnuPlanMonthlyData();
  }, [mealPlanId]);


  const handleMealPlanChange = (event) => {
    const value = event.target.value;
    setMealPlanID(value);
    // Find and set the new plan name
    const selectedPlan = menuPlansListing.find(plan => plan.id === value);
    if (selectedPlan) {
      setMealPlanName(selectedPlan.name);
    }
  };

  const handleOpenModal = (startDate, endDate, weekNo) => {
    // Clear previous data before loading new week
    setSelectedMeals({});
    setMealPlusAddons([]);
    
    setSelectedMealPlusData(weekNo);
    handleMealPlus(startDate, endDate, weekNo);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

console.log("menuPlanMealListing:",menuPlanMealListing);

  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
            overflowY: 'auto',
            padding: '15px',
          }}
        >
          <Select
            value={mealPlanId} // Ensure mealPlanId matches a valid element.id
            onChange={(event) => {
              const value = event.target.value;
              console.log('Selected Value:', value); // Debug
              setMealPlanID(value);
            }}
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Outfit',
              color: '#000', // Ensure text visibility
              background:
                'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
              height: '45px',
              border: '1px solid #B0B0B0',
              lineHeight: '20.16px',
              marginBottom: '17px',
              borderRadius: '10px',
              width: 350,
              backdropFilter: 'blur(20px)',
              '& .MuiSelect-icon': {
                top: 'calc(50% - 12px)',
                right: '10px',
              },
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
            }}
            IconComponent={ExpandMoreIcon}
          >
            {/* Dynamically render menu items */}
            {menuPlansListing.map((element) => (
              <MenuItem
                key={element.id}
                value={element.id}
                sx={{ color: '#000' }}
              >
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap', // Ensures responsiveness when the screen size is smaller
              gap: '1.2em',
            }}
          >
            {/* First Box */}
            <Grid
              item
              sx={{
                display: 'flex',
                gap: '1.5em',
                paddingBottom: '1em', // Add some padding for spacing
                flexGrow: 1,
                width: '67%',
                '@media (max-width: 1580px)': {
                  width: '100%',
                },
              }}
            >
              {/* Left Week */}
              <Grid
                sx={{
                  minWidth: '85px',
                }}
              >
                <Grid
                  sx={{
                    justifyItems: 'flex-start',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    display: 'flex',
                  }}
                >
                  <Grid
                    sx={{
                      ...weekStyle,
                      minWidth: '61px',
                      height: '44px!important',
                    }}
                  >
                    {capitalize(currentMonthName).substr(0, 3)}
                  </Grid>
                  {weekNames.map((element, index) => (
                    <Grid
                      sx={{
                        ...weekStyle,
                        borderRadius: '10px',
                        paddingY: '10px',
                        height: 'auto',
                        fontWeight: '700',
                        maxWidth: '131px',
                        width: '131px',
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                    >
                      {element}
                    </Grid>
                  ))}
                </Grid>
                {menuPlanMealListing.map((element, index) => (
                  <Grid
                    sx={{
                      justifyItems: 'flex-start',
                      alignItems: 'flex-start',
                      textAlign: 'left',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...(selectedMealPlusData === index + 1
                          ? weekStyleActive
                          : {}),
                      }}
                      onClick={() =>
                        handleOpenModal(
                          element.startDate,
                          element.endDate,
                          index + 1
                        )
                      }
                    >
                      {element.isStar && <img src={InActiveCalIcon} alt="" />}
                      <Typography
                        marginBottom="-4px"
                        fontSize={16}
                        color={
                          selectedMealPlusData === index + 1
                            ? '#ffffff'
                            : '#3F2F5B'
                        }
                      >
                        Week
                      </Typography>
                      <Typography
                        fontSize={24}
                        color={
                          selectedMealPlusData === index + 1
                            ? '#ffffff'
                            : '#3F2F5B'
                        }
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    {element.days.map((day, dayIndex) => (
                      <Grid
                        sx={{
                          ...weekStyle,
                          ...weekNewStyle,
                          // borderLeft: '2px solid rgb(242, 80, 64)', // if not use '2px solid transparent'
                          justifyItems: 'flex-start',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          padding: '7px',
                          justifyContent: 'space-between',
                          boxShadow: day.is_available
                            ? '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)'
                            : '',
                          backgroundColor: (day.isOpen) ? '' : "#E8E8E8",
                          maxWidth: '131px',
                          width: ' 100%',
                          flexShrink: 1,
                          flexGrow: 1,
                          maxWidth: '100%',
                          marginLeft: '10px',
                          marginRight: '10px',
                          height: '119px',
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={600}
                          fontSize={11}
                        >
                          {day.isOpen ? day.plan_name : <></> }
                        </Typography>

                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={400}
                          fontSize={10}
                        >
                          {day.isOpen ? <>{day.meal_name}</> : "Day Off" }
                        </Typography>
                        {element.addon_name && (
                          <>
                            <Typography
                              color={'#3F2F5B'}
                              fontWeight={600}
                              fontSize={11}
                            >
                              Addons
                            </Typography>

                            <Typography
                              color={'#3F2F5B'}
                              fontWeight={400}
                              fontSize={10}
                            >
                              {day.addon_name}
                            </Typography>
                          </>
                        )}
                        {!!day.day && (
                          <Box
                            sx={{
                              ...weekStyle,
                              borderRadius: '10px',
                              paddingY: '3px',
                              height: 'auto',
                              marginBottom: 0,
                              marginTop: '4px',
                            }}
                          >
                            <Typography
                              color={'#3F2F5B'}
                              fontWeight={500}
                              fontSize={14}
                            >
                              {day.day}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Second Box */}
            <Grid
              item
              sx={{
                flexGrow: 1,
                width: '30%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%',
                  borderRadius: '15px',
                  boxShadow: '0px 4px 4px 0px #00000026',
                  overflowY: 'auto',
                  padding: '15px',
                }}
              >
                {selectedMealPlusData > 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '15px',
                    }}
                  >
                    <Typography
                      fontSize={22}
                      fontWeight={700}
                      color={'#2F2F3B'}
                    >
                      Week {selectedWeekNo}
                    </Typography>
                    <Button
                      sx={{
                        borderWidth: 1,
                        borderColor: '#F0645B',
                        borderStyle: 'solid',
                        minWidth: '74px',
                      }}
                      onClick={saveMealPlusData}
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <div>
                {weeklyMealPlusData.map((item, index) => (
                    <div key={index}>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color={'#3F2F5B'}
                      >
                        {formatDate(item.date)}
                      </Typography>
                      
                      {item.day_off ? (
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#888',
                            padding: '10px 0',
                            textAlign: 'center',
                            backgroundColor: '#E8E8E8',
                            borderRadius: '10px',
                            marginY: '15px',
                          }}
                        >
                          Day Off
                        </Typography>
                      ) : (
                        <>
                          <Select
                            value="0"
                            onChange={(event) => handleSelectChange(item.date, event.target.value)}
                            sx={{
                              fontSize: '16px',
                              fontWeight: '400',
                              fontFamily: 'Outfit',
                              background: 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
                              height: '45px',
                              border: '1px solid #F4F1F8',
                              lineHeight: '20.16px',
                              borderRadius: '10px',
                              width: '100%',
                              backdropFilter: 'blur(20px)',
                              marginY: '15px',
                              marginTop: '10px',
                              '& .MuiSelect-icon': {
                                top: 'calc(50% - 12px)',
                                right: '10px',
                              },
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0,
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0,
                              },
                            }}
                            IconComponent={ExpandMoreIcon}
                          >
                            <MenuItem value="0" disabled>
                              Select items
                            </MenuItem>
                            {allMealItems.map((mealItem, itemIndex) => (
                              <MenuItem key={itemIndex} value={mealItem}>
                                {mealItem.name}
                              </MenuItem>
                            ))}
                          </Select>

                          <Box
                            sx={{
                              display: 'flex',
                              gap: '10px',
                              marginBottom: '15px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {(item.items || []).map((mealItem, index) => {
                              return (
                                <Button
                                  key={index}
                                  onClick={() => handleRemoveMeal(item.date, mealItem.item_id)}
                                  sx={{
                                    backgroundColor: '#F0645B',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '7px',
                                    padding: '10px 15px',
                                    borderRadius: '100px',
                                    justifyContent: 'center',
                                    '&:hover': {
                                      backgroundColor: '#F0645B',
                                      opacity: 0.9,
                                    },
                                  }}
                                >
                                  <Typography fontSize={12} fontWeight={700} color={'#ffffff'}>
                                    {mealItem.item_name || 'Unknown'}
                                  </Typography>
                                  <img src={CrossIcon} alt="Remove Meal" />
                                </Button>
                              );
                            })}
                            {item.items?.length === 0 && (
                              <Typography fontSize={12} fontWeight={700} color={'#888'}>
                                No meals selected for this date.
                              </Typography>
                            )}
                          </Box>
                        </>
                      )}
                    </div>
                  ))}
                {/* {Object.entries(mealPlusAddons).map(([date, dateData], index) => (
                    <div key={index}>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color={'#3F2F5B'}
                      >
                        {new Date(date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </Typography>
                      
                      {dateData.isDayOff ? (
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#888',
                            padding: '10px 0',
                            textAlign: 'center',
                            backgroundColor: '#E8E8E8',
                            borderRadius: '10px',
                            marginY: '15px',
                          }}
                        >
                          Day Off
                        </Typography>
                      ) : (
                        <>
                          <Select
                            value="0"
                            onChange={(event) => handleSelectChange(date, event.target.value)}
                            sx={{
                              fontSize: '16px',
                              fontWeight: '400',
                              fontFamily: 'Outfit',
                              background: 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
                              height: '45px',
                              border: '1px solid #F4F1F8',
                              lineHeight: '20.16px',
                              borderRadius: '10px',
                              width: '100%',
                              backdropFilter: 'blur(20px)',
                              marginY: '15px',
                              marginTop: '10px',
                              '& .MuiSelect-icon': {
                                top: 'calc(50% - 12px)',
                                right: '10px',
                              },
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0,
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0,
                              },
                            }}
                            IconComponent={ExpandMoreIcon}
                          >
                            <MenuItem value="0" disabled>
                              Select items
                            </MenuItem>
                            {allMealItems.map((item, itemIndex) => (
                              <MenuItem key={itemIndex} value={item}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>

                          <Box
                            sx={{
                              display: 'flex',
                              gap: '10px',
                              marginBottom: '15px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {(selectedMeals[date] || []).map((mealId, mealIndex) => {
                              const meal = allMealItems.find((item) => item.id === mealId);
                              return (
                                <Button
                                  key={mealIndex}
                                  onClick={() => handleRemoveMeal(date, mealId)}
                                  sx={{
                                    backgroundColor: '#F0645B',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '7px',
                                    padding: '10px 15px',
                                    borderRadius: '100px',
                                    justifyContent: 'center',
                                    '&:hover': {
                                      backgroundColor: '#F0645B',
                                      opacity: 0.9,
                                    },
                                  }}
                                >
                                  <Typography fontSize={12} fontWeight={700} color={'#ffffff'}>
                                    {meal?.name || 'Unknown'}
                                  </Typography>
                                  <img src={CrossIcon} alt="Remove Meal" />
                                </Button>
                              );
                            })}
                            {selectedMeals[date]?.length === 0 && (
                              <Typography fontSize={12} fontWeight={700} color={'#888'}>
                                No meals selected for this date.
                              </Typography>
                            )}
                          </Box>
                        </>
                      )}
                    </div>
                  ))} */}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MealPlus;
